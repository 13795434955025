/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { getStorage, setStorage } from "../utills/storage";
import { headers } from "../utills/axios";
import { Alert, Button, Space, message } from "antd";
import { useNavigate } from "react-router-dom";
import { addImgPath} from "../utills/images";

const Profile = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false as boolean);

	const userId = getStorage('userId');
    const [ProfileImg, setProfileImg] = useState(null as any);

    const nameRef = useRef(null) as any
    const businessNameRef = useRef(null) as any
    const businessTypeRef = useRef(null) as any
    const locationRef = useRef(null) as any


	const [formData, setFormData] = useState({
        name: '' as string,
		businessName: '' as string,
        error: '' as string,
        businessType: '' as string,
		location: '' as string
    } as any)

    const onChange = (field: string, value: any) => {
        formData[field] = value;
        setFormData({ ...formData })
	}

	const formValidation = () => {
		return true;
	}

    useEffect(() => nameRef.current.focus(), [])
    
    const handleKeyPress = (event : any, nextInput : any) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          nextInput.current.focus();
        }
      };

	const updateUser = () => {
        setLoading(true)
        axios
            .put(`${VS_PORTAL_API_URL}/users/${userId}`, {
                'name': formData.name,
            }, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    updateBusinessDetails()
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

	const handleChangeProfile = (e: any) => {
        setProfileImg(e.target.files[0]);		
    };

	const updateBusinessDetails = () => {
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/businesses`, {
                'name': formData.businessName,
				'type': formData.businessType,
				'location': formData.location,
            }, { headers: headers() })
            .then((response: { data: {
				[x: string]: any; success: any; message: React.SetStateAction<string>;}; 
			}) => {
                if (response.data.success) {
					message.success('Businsess Details Added Successfully')
                    if (ProfileImg !== '' && ProfileImg !== null) {
                        uploadImage(response.data.objects.id)
                    }
					setStorage('businessId', response.data.objects.id);
					setLoading(false);
					navigate(`/collection`, { replace: true });
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

	const uploadImage = (businessId: any) => {
		const formdata = new FormData();
        formdata.append('file', ProfileImg);

        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/businesses/${businessId}/images`, formdata, {
                headers: {
                    'Authorization': getStorage('token'),
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response: { data: {
				[x: string]: any; success: any; message: React.SetStateAction<string>;}; 
			}) => {
                if (response.data.success) {
                    setStorage('businessImageUrl', response.data.objects.image );
					message.success('Businsess Details Added Successfully')
					
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
     }
        );
    }

	const onSubmit = (event: any) => {
        if (formValidation()) {
            formData.error = '';
            setFormData(formData);
            updateUser();
        }
        event.preventDefault();
        return false;
    }

	return (
		<>
			<section className="signup">
				<div className="container">
					<div className="row">
						<div className="d-flex flex-wrap px-0">
							<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 logo-voice"></div>
							<div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
								<div className="col-6 verification-code m-auto">
									<h5 className="text-center">Welcome to VoiceSphere</h5>

									<form className="mb-4">
                                    <div className="mb-3">
											<label htmlFor="name" className="form-label ">Your name</label>
											<input ref={nameRef} type="text" name="name" className="form-control form-color-bg input-with-placeholder" placeholder="John Smith" value={formData.name} onChange={(event) => onChange('name', event.target.value)}  onKeyDown={(event) => handleKeyPress(event, businessNameRef)}/>
										</div>

										<div className="mb-3">
											<label htmlFor="company" className="form-label ">Name of your organization</label>
											<input ref={businessNameRef} type="text" name="company" className="form-control form-color-bg input-with-placeholder" placeholder="Organization Inc." value={formData.businessName} onChange={(event) => onChange('businessName', event.target.value)}  onKeyDown={(event) => handleKeyPress(event, businessTypeRef)}/>
										</div>
										<div className="mb-3">
											<label htmlFor="text" className="form-label">Organization type</label>
											<select ref={businessTypeRef} className="form-select form-control form-color-bg custom-select" name="buisness" defaultValue={formData.businessType} onChange={(event) => onChange('businessType', event.target.value)}  onKeyDown={(event) => handleKeyPress(event, locationRef)}>
												<option selected></option>
												<option>Academic Research</option>
                                                <option>E-Commerce</option>
                                                <option>Education</option>
                                                <option>Engineering</option>
                                                <option>Finance</option>
                                                <option>HR Recruitment</option>
                                                <option>Information Technology</option>
                                                <option>Insurance</option>
                                                <option>Real Estate</option>
                                                <option>Tech Support</option>
                                                <option>Tourism and Hospitality</option>
												<option>Others</option>
											</select>
										</div>
										<div className="mb-3">
											<label htmlFor="location" className="form-label ">Location</label>
											<input ref={locationRef} type="text" name="location" className="form-control form-color-bg input-with-placeholder" placeholder="New Delhi, India" value={formData.location} onChange={(event) => onChange('location', event.target.value)} />
										</div>

                                        <div className="mb-3">
                                            <div className=" d-flex  flex-row align-items-center">
                                                <div className="col-3 add-icon"  style={{width: "90px",height: "90px",  objectFit: "fill",}}>
                                                    <label htmlFor="logoInput" className="circle-button" >
                                                        <input type="file" id="logoInput" style={{ display: 'none' }}  onChange={(e) => handleChangeProfile(e)} accept="image/*"/>
                                                        <img src={ProfileImg ? URL.createObjectURL(ProfileImg): addImgPath} alt="profile" 
                                                        style={{
                                                        borderRadius: "50%",
                                                        width:ProfileImg ?  "90px" : '',
                                                        height:ProfileImg ?  "90px" : '',
                                                        objectFit: "fill",
                                                    }} 
                                                     />
                                                     </label>
                                                </div>
                                                <div className="col-9 add-heading ">
                                                    <h6 className="m-0">Your Organization’s Logo (optional)</h6>
                                                </div>
                                            </div>
                                        </div>

										{!!formData.error &&
											<><Space>
												<Alert message={formData.error} type="error" showIcon />
											</Space><br></br></>
										}
										<Button type="primary" onClick={(event) => onSubmit(event)} className="btn btn-voice-submit btn-primary welcome-screen-continue-button" loading={loading}>Continue</Button>
									</form>
								</div>


							</div>

						</div>
					</div>
				</div>


			</section>


		</>
	);
};

export default Profile;