/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popconfirm, Space, Table, Tooltip, message } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { FilterValue } from "antd/es/table/interface";
import axios from "axios";
import copy from "copy-to-clipboard";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rectangle272 from "../../images/Rectangle272.svg";
//import EmaiIcon from "../../images/email.svg";
import { headers } from "../../utills/axios";
import { VS_PORTAL_API_URL } from "../../utills/constants";
import { chatTextImgPath, urlCopyImgPath, deleteImgPath } from "../../utills/images";
import { getStorage, setStorage } from "../../utills/storage";
import { AnyComponent } from "styled-components/dist/types";
import { VS_EMBED_URL } from "../../utills/constants";

interface DataType {
  name: any;
  Collection: string;
  Email: string;
  Documents: string;
  Users: string;
  Embed: string;
  DateUploaded: string;
}


interface Props {
  businessId: any;
  isModalOpen: any;
  handleEditCollection: any;
  searchString: any;
  isAdmin: any;
}


interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
  keyword: any;
}

const CollectionsTable: FC<Props> = ({ businessId, handleEditCollection, isModalOpen, searchString, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const userId = getStorage('userId');
  const [collectionList, setCollectionList] = useState([] as any);
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    keyword: searchString
  });

  useEffect(() => {
    getCollectionList();
  }, [isModalOpen])

  const copyToClipboard = (copyText: any) => {
    copy(copyText);
  }

  const getCollectionList = () => {
    setLoading(true)
    setCollectionList([])
    axios
      .get(`${VS_PORTAL_API_URL}/users/${userId}/businesses/${businessId}/collections`, { headers: headers() })
      .then((response: {
        data: {
          objects: any[]; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          if (response.data.objects && response.data.objects?.length) {
            response.data.objects.map(async (collectionItem) => {
              axios
                .get(`${VS_PORTAL_API_URL}/collections/${collectionItem.id}/metrics`, { headers: headers() })
                .then((response: {
                  data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                  };
                }) => {
                  if (response.data.success) {
                    const metricsData: any = response.data.objects;
                    collectionItem.total = metricsData?.total || 0;
                    collectionItem.documents = metricsData?.documents || 0;
                    collectionItem.users = metricsData?.users || 0;
                    collectionItem.messages = metricsData?.messages || 0;
                    collectionItem.createdAt = metricsData?.createdAt || 0;
                    collectionItem.email = 'docs+' + collectionItem?.uniqueId + '@voicesphere.co';
                    collectionItem.isPublic = collectionItem?.isPublic|| false;
                    setCollectionList((list: any) => [...list, { ...collectionItem }])

                  } else {
                    message.error(response?.data.message)
                  }
                }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                  if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                  }
                });

            })
          }
          setLoading(false)

        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          message.error(err?.response?.data.message)
          setLoading(false)
        }
      });
  }

  const deleteCollection = (collectionId: any) => {
    axios.delete(`${VS_PORTAL_API_URL}/collections/${collectionId}`, { headers: headers() })
      .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
        if (response.data.success) {
          message.success('Collection Deleted Succcessfully');
          getCollectionList();
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          message.error(err?.response?.data.message)
          setLoading(false)
        }
      });
  }

  const navigateToFolderDocuments = (collectionId: any, collectionName: string) => {
    setLoading(false)
    setStorage('collectionName', collectionName);
    setStorage('collectionId', collectionId)

    navigate(`/documents/${businessId}/${collectionId}`);
  }

  const navigateToChat = (collectionId: AnyComponent, collectionName: string) => {
    setLoading(false)
    setStorage('collectionName', collectionName);
    setStorage('collectionId', collectionId)
    setStorage('newChatInitiated', true);
    navigate(`/chat/${businessId}/${collectionId}`);
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "Collection",
      dataIndex: "Collection",
      width: "40%",
      className: "mySection action-btn table-heading",
      render: (name: any, record: any) => {
        return (
          <Space >
            <span className=" w-100" onClick={() => navigateToFolderDocuments(record.id, record.name)}>{record.name}</span>
            {isAdmin && (
              <a
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditCollection(record)
                }}
                className="edit-imgHover"
                rel="noreferrer"
                key={`documents_table_row_url_${record.id}`}
              >
                <img src={Rectangle272} alt="" />
              </a>
            )}
          </Space>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "Email",
      width: "10%",
      className: "table-heading",
      render: (name: any, record: any) => {
        return (
          <Space onClick={(event) => {
            event.stopPropagation();
            copyToClipboard(record?.email)
          }} >
            <Tooltip
              title={
                "Click to copy " +
                record?.email +
                ". Documents sent here are automatically added to this collection."
              }
            >
              <span className="email-link">Copy email</span>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: "Documents",
      dataIndex: "Documents",
      width: "10%",
      className: "table-heading",
      render: (name: any, record: any) => {
        return (
          <Space className="w-100 text-cls" onClick={() => navigateToFolderDocuments(record.id, record.name)}>

            <span>{record.documents}</span>
          </Space>
        );
      },
    },
    {
      title: "Users",
      dataIndex: "Users",
      width: "10%",
      className: "table-heading",
      render: (name: any, record: any) => {
        return (
          <Space className="w-100 text-cls" onClick={() => navigateToFolderDocuments(record.id, record.name)}>
            <span>{record.users}</span>
          </Space>
        );
      },
    },
    {
      title: "Embed?",
      dataIndex: "isPublic",
      width: "10%",
      className: "table-heading",
      render: (isEmbed, record: any) => {
        if (isEmbed) {
          const linkToCopy = `<iframe style="width: 100% !important" src="${VS_EMBED_URL}${record.id}" width="100%" height="800" scrolling="no" allow="microphone *"></iframe>`;
          return (
            <><span className="text-cls">Embed</span><Space onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(linkToCopy).then(() => {
              }, () => {
              });
            } }>
              <Tooltip title="Click to copy link">
                <img src={urlCopyImgPath}></img>
              </Tooltip>
            </Space></>
          );
        }
        return null;
      },
    },
    {
      title: "Messages",
      dataIndex: "messages",
      width: "10%",
      className: "table-heading",
      render: (name: any, record: any) => {
        return (
          <Space className="w-100 text-cls" onClick={() => navigateToFolderDocuments(record.id, record.name)}>
            <span>{record.messages}</span>
          </Space>
        );
      },
    },

    {
      title: "Date Uploaded",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
      dataIndex: 'createdAt',
      width: "15%",
      className: 'file-size dates table-heading action-btn',
      render: (name: any, record: any) => {
        return <Space>
          <p className="text-cls">{moment(record.createdAt).format('MMM DD, YYYY')}</p>
        </Space>
      },
    },
    {
      title: "",
      dataIndex: 'createdAt',
      width: "5%",
      className: 'file-size dates table-heading action-btn',
      render: (name: any, record: any) => {
        return <Space>
          <a className="edit-imgHover"
              rel="noreferrer"
              key={`documents_table_row_url_chat_${record.id}`}

              onClick={(event) => {
                event.stopPropagation();
                navigateToChat(record.id, record.name)
              }}
            >
              <img src={chatTextImgPath} alt="" />
          </a>
          {isAdmin && (<Popconfirm
            placement="right"
            title={'Are you sure to delete this collection?'}
            description={''}
            onConfirm={(event: any) => {
              event.stopPropagation();
              deleteCollection(record.id)
            }}
            onCancel={(event: any) => event.stopPropagation()}
            okText="Yes"
            cancelText="No"
          >
            <a href="#." className="ms-4" key={`document_delete_${record.id}`} onClick={(event) => event.stopPropagation()}>
              <img src={deleteImgPath} alt="" />
            </a>
          </Popconfirm>)}
        </Space>
      },
    }
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    keyword: any
  ): any => {
    setTableParams({
      pagination,
      keyword
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCollectionList([]);
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={collectionList}
      rowKey={(record: any) => record.id}
      pagination={tableParams.pagination}
      onChange={handleTableChange}
      loading={loading}
      size="small"
      style={{ margin: '0.5rem' }}
      rowClassName={"orgRight-row"}
      onRow={(record: any) => {
        return {
          onClick: () => navigateToFolderDocuments(record.id, record.name)
        };
      }
      }
    />
  );
};

export default CollectionsTable;
