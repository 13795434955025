import React, { useEffect } from 'react';
import { VS_PDF_EMBED_CLIENT_ID } from './utills/constants';


const AdobeViewer = (fileUrl) => {
  const clientId = VS_PDF_EMBED_CLIENT_ID;
  //console.log('fileUrl', fileUrl.fileUrl)
  
  const pdfFileUrl = fileUrl.fileUrl
  
  const pdfFileName = fileUrl.fileName

  useEffect(() => {
    //console.log('location.hash', location.hash)
    let pageNumber = 1;
    if (location.hash) {
      const locationHashSplit = location.hash.split('#')
      const paramIdSplit = locationHashSplit[1].split("***");       
      pageNumber = paramIdSplit[1];
    }

    const script = document.createElement('script');
    script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
    script.onload = () => {

      document.addEventListener("adobe_dc_view_sdk.ready", function () {
        window.adobe_dc_sdk['dc-core-loaded'] = false;
        // eslint-disable-next-line no-undef
        var adobeDCView = new AdobeDC.View({ clientId, divId: "adobe-dc-view" });
        adobeDCView.previewFile({
          content: { location: { url: pdfFileUrl } },
          metaData: { fileName: pdfFileName, hasReadOnlyAccess: true }
        }, { embedMode: "FULL_WINDOW", defaultViewMode: "FIT_PAGE", showAnnotationTools: true, showDownloadPDF: true, showFullScreen: true }
        ).then((adobeViewer) => {
          adobeViewer.getAPIs().then((apis) => {
            apis.gotoLocation(parseInt(pageNumber))

          });
        });
      });
    };
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [location.hash, pdfFileUrl]); // Depend on clientId and search string

  return <div id="adobe-dc-view"></div>;
};

export default AdobeViewer;
