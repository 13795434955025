import React from "react";
import { logoImgPath } from "../../utills/images";

const DocumentsHeader = () => {

    return (
        <>
            <header className="headerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="iuc-bg">
                                <img src={logoImgPath} alt="logo" title="VoiceSphere" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )

}

export default DocumentsHeader;