/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from "react";
import Header from "../components/header/Header";
import { Button, Modal, message, Select as AntSelect, Spin } from "antd";
import { profileImgPath } from "../utills/images";
import axios from "axios";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { headers } from "../utills/axios";
import { clearStorage, getStorage, setStorage } from "../utills/storage";
import { deleteImgPath } from "../utills/images";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ChartConfiguration } from "chart.js";
import Chart from "chart.js/auto";
import { format, addDays, startOfMonth, endOfMonth } from "date-fns";

const Setting = () => {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState(false as any);
  const [defaultTab, setDefaultTab] = useState(0);
  const [ProfileImg, setProfileImg] = useState<any>("");
  const iframeSrc = `${window.location.origin}/billing`;
  const [userData, setUserData] = useState({
    email: "",
    name: "",
    id: "",
    imageUrls: []
  });

  const userId = getStorage("userId");

  const handleChangeProfile = (e: any) => {
    const { files } = e.target;
    setProfileImg(files[0]);
  };

  const currentDate: any = new Date();
  const [usageData, setUsageData] = useState([] as any);
  const currentMonth = `${currentDate.getMonth() + 1}`
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [dateLabels, setDateLabels] = useState<string[]>([]);
  // Use useRef to store the chart instance
  const chartRef = useRef<Chart | null>(null);

  const showUserDeleteConfirm = () => {
    confirm({
      title: "Are you sure want to delete the user?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        deleteUser();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updateUser = () => {
    setLoading(true);
    axios
      .put(
        `${VS_PORTAL_API_URL}/users/${userId}`,
        { name: userData.name },
        { headers: headers() }
      )
      .then((response) => {
        if (response.data.success) {
          message.success("Profile Updated Successfully");
          setLoading(false);
        } else {
          message.error(response?.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message);
          }
          setLoading(false);
        }
      });
  };

  const generateDateLabels = (month: number, year: number) => {
    const startDate = startOfMonth(new Date(year, month - 1));
    const endDate = endOfMonth(new Date(year, month - 1));
    const labels: string[] = [];
  
    // Calculate the interval between dates
    const daysBetween = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    const interval = Math.max(1, Math.ceil(daysBetween / 7)); // At least one day, or evenly divide into 7 intervals
  
    let currentDate = startDate;
  
    while (currentDate <= endDate && labels.length < 7) {
      const formattedDate = `${format(currentDate, "dd MMM")}`;
      labels.push(formattedDate);
      currentDate = addDays(currentDate, interval);
    }
  
    return labels;
  };
  
  const updateUsageChart = (month: string, year: string) => {
    axios
      .get(
        `${VS_PORTAL_API_URL}/users/${userId}/usages?month=${month}&year=${year}`,
        {
          headers: headers(),
        }
      )
      .then((response) => {
        if (response.data.success) {
          setUsageData(response.data.objects);
          setLoading(false);
        } else {
          message.error(response?.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message);
          }
          setLoading(false);
        }
      });
  };

  const handleYearChange = (value: string) => {
    setSelectedYear(value);
  };

  const handleMonthChange = (value: string) => {
    setSelectedMonth(value);
  };

  const updateUsageData = () => {
    setLoading(true);
    updateUsageChart(selectedMonth, selectedYear);
    const labels = generateDateLabels(Number(selectedMonth), Number(selectedYear));
    setDateLabels(labels);
  };

  const uploadImage = () => {
    const formdata = new FormData();
    formdata.append('file', ProfileImg);
    setImageUpload(false)
    setLoading(true)
    axios
      .post(`${VS_PORTAL_API_URL}/users/${userId}/images`, formdata, {
        headers: {
          'Authorization': getStorage('token'),
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: {
        data: {
          [x: string]: any; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          message.success('Profile Photo Successfully');          
          getUser(true);
          setLoading(false)
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          message.error(err?.response?.data.message)
          setLoading(false)
        }
      }
      );
}

useEffect(() => {
  if (ProfileImg) {
    uploadImage();
  }
}, [ProfileImg]);

  useEffect(() => {
    if (userId) {
      getUser();
      updateUsageData();
    }
  }, [userId, selectedMonth, selectedYear]);

  useEffect(() => {
    if (defaultTab === 2) {
      if (usageData) {
        const pagesCount = usageData.map((data: any) => data.pagesCount)
        const messagesCount = usageData.map((data: any) => data.messagesCount)
        const dateList = usageData.map((data: any) => {
          return `${format(new Date(data.date), "dd MMM")}`
        })
        const config: ChartConfiguration<"bar"> = {
          type: "bar",
          data: {
            labels: dateList,
            datasets: [
              {
                label: "Pages Count",
                data: pagesCount,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
              {
                label: "Messages Count",
                data: messagesCount,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: "category",
                title: {
                  display: true,
                  text: "Date",
                  font: {
                    size: 18,
                    family: "Red Hat Text",
                  },
                },
                stacked: true,
                grid: {
                  display: false,
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Value",
                  font: {
                    size: 18,
                    family: "Red Hat Text",
                  },
                },
                stacked: true,
                ticks: {
                  font: {
                    size: 18,
                    family: "Red Hat Text",
                  },
                },
              },
            },
            plugins: {
              title: {
                display: true,
                text: "Usage Chart",
                font: {
                  size: 18,
                  family: "Red Hat Text",
                },
              },
              legend: {
                display: true,
                labels: {
                  font: {
                    size: 18,
                    family: "Red Hat Text",
                  },
                },
              },
            },
          },
        };
        
        const chartCanvas = document.getElementById("usageChart") as HTMLCanvasElement;

        // Check if the chartRef is initialized and destroy the previous chart
        if (chartRef.current) {
          chartRef.current.destroy();
        }
        // Create the new chart instance
        const newChart = new Chart(chartCanvas, config);

        // Store the chart instance in chartRef
        chartRef.current = newChart;
      }
    }
  }, [defaultTab, usageData, dateLabels]);

  const getUser = (fromImageUpload?: boolean) => {
    setLoading(true);
    axios
      .get(`${VS_PORTAL_API_URL}/users/${userId}`, { headers: headers() })
      .then((response) => {
        if (response.data.success) {
          setUserData(response.data.objects);
          setStorage('roles', JSON.stringify(response.data.objects?.roles || []));
          setStorage('imageUrls', JSON.stringify(response.data.objects?.imageUrls || []));
          setImageUpload(fromImageUpload)
          setLoading(false);
        } else {
          message.error(response?.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message);
          }
          setLoading(false);
        }
      });
  };

  const deleteUser = () => {
    setLoading(true);
    axios
      .delete(`${VS_PORTAL_API_URL}/users/${userId}`, { headers: headers() })
      .then((response) => {
        if (response.data.success) {
          message.success("User Deleted Successfully");
          clearStorage();
          navigate(`/login`, { replace: true, state: { Signin: "false" } });
          setLoading(false);
        } else {
          message.error(response?.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message);
          }
          setLoading(false);
        }
      });
  };

  return (
    <>
      <Header imageUpload={imageUpload} />
      <Spin tip="Loading" size="large" spinning={loading}>
        <section className="profile2 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 pp2-sidebar">
                <nav>
                  <ul>
                    <li
                      className={`m-auto ${
                        defaultTab === 0 ? "active" : ""
                      }`}
                    >
                      <a href="#." onClick={() => setDefaultTab(0)}>
                        Profile
                      </a>
                    </li>
                    <li
                      className={`m-auto ${
                        defaultTab === 1 ? "active" : ""
                      }`}
                    >
                      <a href="#." onClick={() => setDefaultTab(1)}>
                        Billing
                      </a>
                    </li>
                    <li
                      className={`m-auto ${
                        defaultTab === 2 ? "active" : ""
                      }`}
                    >
                      <a href="#." onClick={() => setDefaultTab(2)}>
                        Usage
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              {defaultTab === 0 && (
                <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                  <div className="m-2 d-flex flex-row justify-content-between ">
                    <h6 className="p-profile">Profile</h6>
                    <div
                      className="p-profile d-flex flex-row align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={showUserDeleteConfirm}
                    >
                      <a href="#." className="ml-2">
                        <img src={deleteImgPath} alt="" />
                      </a>
                      <h6
                        style={{
                          fontSize: "16px",
                          lineHeight: "20.8.sp",
                          marginTop: "10px",
                          fontWeight: "400",
                          color: "#E47C67",
                        }}
                      >
                        Delete {userData.name}
                      </h6>
                    </div>
                  </div>
                  <form action="" method="">
                    <div className="col-lg-8 col-md-8 col-sm-8 col-12 d-flex flex-sm-wrap justify-content-sm-start justify-content-center">
                      <div className="pro-img d-flex flex-column ">
                        <div
                          style={{
                            width: "90px",
                            height: "90px",
                          }}
                        >
                          <img
                            src={
                              !userData?.imageUrls?.length ?
                              ProfileImg
                                ? URL.createObjectURL(ProfileImg)
                                : profileImgPath
                                :
                                userData.imageUrls[0]
                            }
                            style={{
                              borderRadius: "50%",
                              width: "90px",
                              height: "90px",
                              objectFit: "fill",
                            }}
                            alt=""
                          />
                        </div>
                        <label htmlFor="uploadFile" className="upload-label">
                          Change photo
                        </label>
                        <input
                          onChange={(e) => handleChangeProfile(e)}
                          type="file"
                          id="uploadFile"
                          accept="image/*"
                          style={{ display: 'none' }}
                        />
                      </div>
                      <div className="mb-3 align-self-center pp-inputWidth">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label pp-username"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          value={userData.name}
                          className="form-control pp-text bg-color"
                          placeholder="John Smith"
                          onChange={(e) =>
                            setUserData({ ...userData, name: e.target.value })
                          }
                        />
                        <Button
                          type="primary"
                          className="btn btn-voice-submit btn-primary mt-4"
                          onClick={() => updateUser()}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {defaultTab === 1 && (
                <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                  <h6 className="p-profile">Billing</h6>
                  <iframe
                    src={iframeSrc}
                    style={{
                      height: "100vh",
                      width: "100%",
                    }}
                  ></iframe>
                </div>
              )}
              {defaultTab === 2 && (
                <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                  <h6 className="p-profile">Usage</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <AntSelect
                        className="mr-2"
                        style={{ width: "120px" }}
                        value={selectedYear}
                        onChange={handleYearChange}
                      >
                        <AntSelect.Option value="2022">2022</AntSelect.Option>
                        <AntSelect.Option value="2023">2023</AntSelect.Option>
                      </AntSelect>
                      <AntSelect
                        style={{ width: "120px" }}
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        <AntSelect.Option value="1">January</AntSelect.Option>
                        <AntSelect.Option value="2">February</AntSelect.Option>
                        <AntSelect.Option value="3">March</AntSelect.Option>
                        <AntSelect.Option value="4">April</AntSelect.Option>
                        <AntSelect.Option value="5">May</AntSelect.Option>
                        <AntSelect.Option value="6">June</AntSelect.Option>
                        <AntSelect.Option value="7">July</AntSelect.Option>
                        <AntSelect.Option value="8">August</AntSelect.Option>
                        <AntSelect.Option value="9">September</AntSelect.Option>
                        <AntSelect.Option value="10">October</AntSelect.Option>
                        <AntSelect.Option value="11">November</AntSelect.Option>
                        <AntSelect.Option value="12">December</AntSelect.Option>
                      </AntSelect>
                    </div>
                  </div>
                  <div>
                    <canvas id="usageChart" width={400} height={200}></canvas>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Spin>
    </>
  );
};

export default Setting;