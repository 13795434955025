/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Button, Space, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { headers } from "../utills/axios";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { setCurrentUserData } from "../utills/global";
import { vectorImgPath } from "../utills/images";
import { getStorage } from "../utills/storage";

const Collection = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false as boolean);

    const businessId =  getStorage("businessId");

    const [formData, setFormData] = useState({
        collectionName: '' as string
    } as any)

    const onChange = (field: string, value: any) => {
        formData[field] = value;
        setFormData({ ...formData })
    }

    const formValidation = () => {
        return true;
    }

    const createCollectionDetails = () => {
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/collections`, {
                'name': formData.collectionName,
                'description': formData.collectionName,
                'businessId': businessId,
            }, { headers: headers() })
            .then((response: { data: {
                [x: string]: any; success: any; message: React.SetStateAction<string>}
            }) => {
                if (response.data.success) {
                    const collectionId = response.data.objects.id;
                    setLoading(false)
                    message.success('Collections Details Created Successfully')
                    setCurrentUserData()
                    navigate(`/adddocuments/${businessId}/${collectionId}`);
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const onSubmit = (event: any) => {
        if (formValidation()) {
            formData.error = '';
            setFormData(formData);
            createCollectionDetails();
        }
        event.preventDefault();
        return false;
    }

    return (
        <>
            <section className="signup">
                <div className="container">
                    <div className="row">
                        <div className="d-flex flex-wrap px-0">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 logo-voice"></div>
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                <div className="col-6 collection verification-code m-auto">
                                    <img src={vectorImgPath} alt="icon" title="collection icon" className="mb-5" />
                                    <h5 className="text-center">Create Collection</h5>
                                    <p>A collection is a repository of documents that VoiceSphere uses to create a Knowledge Base. Use it to group documents relevant to a team. Only members invited to the collection can ask questions from that Knowledge Base</p>
                                    <form className="mb-4">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" name="name" className="form-control bg-color" placeholder="Collection Name" value={formData.collectionName} onChange={(event) => onChange('collectionName', event.target.value)} />
                                        </div>
                                        {!!formData.error &&
                                            <><Space>
                                                <Alert message={formData.error} type="error" showIcon />
                                            </Space><br></br></>
                                        }
                                        <Button type="primary" onClick={(event) => onSubmit(event)} className="btn btn-voice-submit btn-primary" loading={loading}>Create Collection</Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default Collection;