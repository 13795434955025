/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UploadProps } from 'antd';
import { Button, Space, Upload, message } from "antd";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from "../components/header/Header";
import DocumentIcon from "../images/documents.svg";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { setCurrentUserData } from '../utills/global';
import { getStorage } from "../utills/storage";
import WebPageUrl from '../components/WebPageUrl/WebPageUrl';

const { Dragger } = Upload;


const Documents = () => {
    const routeParams = useParams();
    const collectionId = routeParams.collectionId || getStorage('collectionId');
    const businessId = routeParams.businessId || getStorage('businessId');
    const [showmodal, setshowmodal] = useState(false)
    const navigate = useNavigate();

    const decode = (val: any) => {
        if (typeof val === 'string') {
            return JSON.parse(val);
        }
    }
    const props: UploadProps = {
        name: 'files',
        method: 'PUT',
        multiple: true,
        accept: '.pdf,.doc,.docx,.txt,.ppt,.pptx,.csv,.jpg,.jpeg,.png,.xlsx,.xls,.mp4,.avi,.mov,.webm,.wmv,.qt,.heic,.heif',
        action: `${VS_PORTAL_API_URL}/collections/${collectionId}/documents`,
        headers: {
            Authorization: `${getStorage('token')}`
        },
        beforeUpload(file) {
            const fileType = file.type;
            const isLt10M = file.size / 1024 / 1024 <= 10;
    
            // Allow certain file types to bypass the size check
            const isSizeCheckBypassed = (
                fileType === 'application/pdf' ||
                fileType === 'video/mp4' ||
                fileType === 'video/avi' ||
                fileType === 'video/mov' ||
                fileType === 'video/webm' ||
                fileType === 'video/quicktime'
            );
    
            if (!isSizeCheckBypassed && !isLt10M) {
                message.error('File must be smaller than 10MB!');
                return false;
            }
    
            return true;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                if (info.fileList.length === info.fileList.filter((fitem: any) => fitem.status === 'done')?.length) {
                    const validFormat = decode(getStorage(`signIn`))
                    if (validFormat) {
                        navigateToDocumentsList();
                    } else {
                        navigateToKnowledgebase();
                    }
                }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const navigateToDocumentsList = () => {
        navigate(`/documents/${businessId}/${collectionId}`, { replace: true });
    }

    const navigateToKnowledgebase = () => {
        navigate(`/knowledgebase/${businessId}/${collectionId}`, { replace: true });
    }

    useEffect(() => {
        if (getStorage('businesses') == undefined || getStorage('collections') == undefined || getStorage('businessId') == undefined || getStorage('collectionId') == undefined) {
            setCurrentUserData()
        }
    }, [getStorage('businesses'), getStorage('collections'), getStorage('businessId'), getStorage('collectionId')]);

    return (
        <>
            <Header></Header>
            <section id="documentUpload" className="bg-white">
                <div className="container" key={'document-upload-container'}>
                    <div className="row">
                        <div className="inner-sent-invite d-flex justify-content-center align-items-center">
                            <div className="col-lg-4 text-center">
                                <span>
                                    <img src={DocumentIcon} alt="document" title="documents title" />
                                </span>
                                <h1>Add Documents to {getStorage('collectionName')}</h1>                                
                                <Space direction='vertical'>
                                    <Dragger {...props}>
                                        <Button className="btn-sent">Upload Documents From Your Computer</Button>
                                    </Dragger>
                                    {/*<SelectSource />*/}
                                    <Button className="btn-sent" onClick={() => setshowmodal(true)}>Upload Documents From Web</Button>
                                </Space>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showmodal && <WebPageUrl modalClose={() => setshowmodal(false)} collectionId={collectionId} businessId={businessId} />}
        </>
    );
};

export default Documents;