/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { useLocation , useNavigate } from 'react-router-dom';
import axios from 'axios';
import { VS_PORTAL_API_URL } from "../utills/constants";
import { setStorage } from "../utills/storage";
import { Alert, Button, Space, message } from "antd";
import {  useGoogleLogin } from '@react-oauth/google';
import { headers } from "../utills/axios";

const Signup = () => {

    const navigate = useNavigate();    

    const inputRef = useRef(null) as any;
    const OTPRef = useRef(null) as any;

    const params  = useLocation();

    const [showVerification, setShowVerification] = useState(false as boolean);

    const [loading, setLoading] = useState(false as boolean);

    const [signIn, setSignIn] = useState(false as boolean);

    useEffect(() => {
    inputRef.current.focus();
     const State = params.state?.Signin == undefined ? false : params.state?.Signin
      setSignIn(State) 
    }, [])
    

    const [formData, setFormData] = useState({
        email: '' as string,
        error: '' as string,
        password: '' as string
    } as any)

    const onChange = (field: string, value: any) => {
        formData[field] = value;
        setFormData({ ...formData })
    }

    const emailValidation = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!formData.email || regex.test(formData.email) === false) {
            setFormData({ email: formData.email, error: 'Please Enter Email Address', password: '' })
            return false;
        }
        return true;
    }
    const passwordValidation = () => {
        if (!formData.password) {
            setFormData({ email: formData.email, error: 'Please Enter Verification Code', password: formData.password })
            return false;
        }
        return true;
    }
    const sendVerificationCode = () => {
        axios
            .put(`${VS_PORTAL_API_URL}/users/verification_code`, {
                'email': formData.email,
            })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    setShowVerification(true)
                    OTPRef.current.focus();
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; status:number }; }) => {
                if (!err?.response?.data?.success) {
                    if(err?.response.status === 404){
                        setSignIn(false);
                        creatUser()                       
                    }
                    else{
                        message.error(err?.response?.data.message)
                        setLoading(false)
                    }
                  
                    
                }
            });
    }

    const loginUser = () => {
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/users/login`, {
                'email': formData.email,
                'password': formData.password,
            })
            .then((response: { data: { success: any; objects: { token: any; refreshToken: any; id: any; }; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    setStorage('token', response.data.objects.token);
                    setStorage('refreshToken', response.data.objects.refreshToken);
                    setStorage('userId', response.data.objects.id);
                    getCurrentUserData(response.data.objects.id)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    const getCurrentUserData = (userId: any) => {
        setLoading(true)
        axios
            .get(`${VS_PORTAL_API_URL}/users/${userId}`, { headers: headers() })
            .then((response: { data: { success: any; objects: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    setStorage('roles', JSON.stringify(response.data.objects?.roles || []));
                    setStorage('imageUrls', JSON.stringify(response.data.objects?.imageUrls || []));
                    setStorage('businesses', JSON.stringify(response.data.objects?.businesses || []));
                    setStorage('collections', JSON.stringify(response.data.objects?.collections || []));
                    if(response.data.objects?.collections?.length) {
                        const defaultCollection: any = response.data.objects?.collections[0]
                        setStorage('businessId', defaultCollection.businessId)
                        setStorage('collectionName', defaultCollection.name)
                        setStorage('collectionId', defaultCollection.id)
                    }   
                    setLoading(false)                   
                    if (!signIn) {
                        message.success('Signup Successfully')
                        setStorage('signIn', false);
                        navigate('/profile', { replace: true });
                        window.location.reload()
                    } else {
                        message.success('Signin Successfully');
                        setStorage('signIn', true);
                        navigate('/', { replace: true });
                        window.location.reload()
                    }
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    const creatUser = () => {
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/users`, {
                'email': formData.email,
            })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    sendVerificationCode()
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if(err?.response?.data.message === 'You have already signed up.'){
                        setSignIn(true);
                        sendVerificationCode()
                        }
                        
                    else {
                        message.error(err?.response?.data.message)

                    }
                    setLoading(false)
                }
            });
    }

    const onSubmit = (event: any) => {
        if (emailValidation()) {
            formData.error = '';
            setFormData(formData);
            if (!signIn) {
                creatUser();
            } else {
                setLoading(true)
                sendVerificationCode()
            }
        }
        event.preventDefault();
        return false;
    }

    const onSubmitVerficationCode = (event: any) => {
        if (passwordValidation()) {
            formData.error = '';
            setFormData(formData);
            loginUser();
        }

        event.preventDefault();
        return false;
    }

    const handleSignIn = (state: boolean) => {
        setSignIn(state);
    }

    const handleGoogleLoginSuccess = (successResponse: any) => {        
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/users/google_oauth_login`, {
                'access_token': successResponse.access_token
            })
            .then((response: { data: { success: any; objects: { token: any; refreshToken: any; id: any; isNewUser: boolean; }; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    setStorage('token', response.data.objects.token);
                    setStorage('refreshToken', response.data.objects.refreshToken);
                    setStorage('userId', response.data.objects.id);
                    setLoading(false)
                    if (response.data.objects.isNewUser) {
                        message.success('Signup Successfully')
                        navigate('/profile', { replace: true });
                        window.location.reload()
                    } else {
                        message.success('Signin Successfully');
                        navigate('/', { replace: true });
                        window.location.reload()
                    }

                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    const handleGoogleLogin = useGoogleLogin({
        flow: 'implicit',
        onSuccess: tokenResponse => handleGoogleLoginSuccess(tokenResponse),
        onError: (error: any) => message.error('Login Failed:', error)
    });    

    return (
        <>
            <section className="signup">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 logo-voice"></div>
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                            {!showVerification &&
                                <div className="col-6 col-lg-6 inner-container">
                                    <h1 className="text-center heading-voice mb-4 mt-4 h3">{signIn ? 'Sign In' : 'Sign up' }</h1>
                                    <form className="mb-4" onSubmit={(event) => onSubmit(event)}>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label email-voice email-input">Email</label>
                                            <input ref={inputRef} type="email" name="email" value={formData.email} onChange={(event) => onChange('email', event.target.value)} className=" form-control bg-color"></input>
                                        </div>
                                        {!!formData.error &&
                                            <><Space style={{
                                                width: '100%'
                                            }}>
                                                <Alert message={formData.error} type="error" showIcon style={{
                                                    width: '100%'
                                                }} />
                                            </Space><br></br></>
                                        }
                                       <Button type="primary"  className="btn btn-voice-submit btn-primary btn-primary mb-4 custom-button continue-email-input " loading={loading} htmlType="submit">Continue</Button>

                                    </form>
                                    <h6 className="text-center mb-3 text-light mb-5 custom-heading .btn-or">Or</h6>
                                    <div className="col-md-12 google-signup mb-5 btn-google-signin">
                                        <a href="#." onClick={()=> handleGoogleLogin()}>Sign in with Google</a>
                                    </div>
                                    {!signIn &&
                                    <><h6 className="text-center">Have an account?<a href="#." onClick={() => handleSignIn(true)}> Sign in</a></h6></>
                                    }
                                     {!!signIn &&
                                    <><h6 className="text-center">New User?<a href="#." onClick={() => handleSignIn(false)}> Sign up</a></h6></>
                                    }
                                </div>
                            }
                            {!!showVerification &&
                                <div className="col-6 verification-code m-auto">
                                    <h5 className="text-center email-input">Enter verification code</h5>
                                    <p>We’ve sent a verification code to your email. Enter the code below.</p>
                                    <form className="mb-4" onSubmit={(event) => onSubmitVerficationCode(event)}>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label email-voice">Verification code</label>
                                            <input ref={OTPRef} type="password" className="form-control bg-color" placeholder="********" onChange={(event) => onChange('password', event.target.value)} />
                                        </div>
                                        {!!formData.error &&
                                            <><Space>
                                                <Alert message={formData.error} type="error" showIcon />
                                            </Space><br></br></>
                                        }
                                        <Button type="primary" className="text-center btn btn-voice-submit btn-primary custom-button continue-email-input" loading={loading} htmlType="submit">Continue</Button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Signup;