/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Checkbox, Space, Spin, Tooltip, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import BackIcon from "../images/Back.svg";
import Close from '../images/Close.png';
import help_icon from "../images/helpIcon.svg";
import { headers } from "../utills/axios";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { isAdmin } from "../utills/global";
import { vectorImgPath } from "../utills/images";
import { getStorage, setStorage } from "../utills/storage";
import CollectionsTable from "./Table/CollectionsTable";

const CollectionList = () => {

    const [isEmbed, setisEmbed] = useState(false as any);

    const navigate = useNavigate();

    const routeParams = useParams();

    const collectionNameRef = useRef(null) as any

    const [loading, setLoading] = useState(false as boolean);
    const [isModalOpen, setisModalOpen] = useState(false as boolean)
    const [modalType, setmodalType] = useState('create' as string)
    const [collectionId, setcollectionId] = useState('' as string)
    const searchString: string = '';

    const [formData, setFormData] = useState({
        collectionName: '' as string
    } as any)

    const userId = getStorage('userId');

    const businessId = routeParams.businessId;

    const handleCreateCollection = () => {
        setisModalOpen(true)
        setTimeout(() => collectionNameRef.current.focus(), 300);
        setFormData({ collectionName: '' })
        setmodalType('create')
    }

    const handleEditCollection = (item: any) => {
        setisModalOpen(true)
        setTimeout(() => collectionNameRef.current.focus(), 300);
        setFormData({ collectionName: item?.name })
        setcollectionId(item?.id)
        setisEmbed(item?.isPublic);
        setmodalType('update')
    }


    const onChange = (field: string, value: any) => {
        formData[field] = value;
        setFormData({ ...formData })
    }
    const formValidation = () => {
        return true;
    }

    const onSubmit = (event: any) => {
        if (formValidation()) {
            formData.error = '';
            setFormData(formData);
            createCollectionDetails();
        }
        event.preventDefault();
        return false;
    }
    const onUpdate = (event: any) => {
        event.preventDefault();
        if (formValidation()) {
            formData.error = '';
            setFormData(formData);
            updateCollectionDetails(); // Call without parameters
        }
        return false;
    };


    const navigateToAddDocuments = (collectionId: any, collectionName: string) => {
        setLoading(false)
        setStorage('collectionName', collectionName);
        setStorage('collectionId', collectionId)
        navigate(`/adddocuments/${businessId}/${collectionId}`);
    }

    const getCollectionList = () => {
        setLoading(true)
        axios
            .get(`${VS_PORTAL_API_URL}/users/${userId}/businesses/${businessId}/collections`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any[]; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    if (response.data.objects && response.data.objects?.length) {
                        response.data.objects.map(async (collectionItem) => {
                            axios
                                .get(`${VS_PORTAL_API_URL}/collections/${collectionItem.id}/metrics`, { headers: headers() })
                                .then((response: {
                                    data: {
                                        objects: any; success: any; message: React.SetStateAction<string>;
                                    };
                                }) => {
                                    if (response.data.success) {
                                        const metricsData: any = response.data.objects;
                                        collectionItem.total = metricsData?.total || 0;
                                        collectionItem.documents = metricsData?.documents || 0;
                                        collectionItem.users = metricsData?.users || 0;
                                        collectionItem.messages = metricsData?.messages || 0;
                                        collectionItem.createdAt = metricsData?.createdAt || 0;
                                        collectionItem.email = 'docs+' + collectionItem?.uniqueId + '@voicesphere.co';

                                    } else {
                                        message.error(response?.data.message)
                                    }
                                }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                                    if (!err?.response?.data?.success) {
                                        message.error(err?.response?.data.message)
                                    }
                                });

                        })
                    }
                    setLoading(false)

                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    const createCollectionDetails = () => {
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/collections`, {
                'name': formData.collectionName,
                'description': formData.collectionName,
                'businessId': businessId,
                'isPublic': isEmbed
            }, { headers: headers() })
            .then((response: {
                data: {
                    [x: string]: any; success: any; message: React.SetStateAction<string>
                }
            }) => {
                if (response.data.success) {
                    setLoading(false)
                    message.success('Collections Details Created Successfully')
                    setisModalOpen(false)
                    /** Navigate To Upload Documents After Create Collection **/
                    navigateToAddDocuments(response.data.objects.id, formData.collectionName);
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    const updateCollectionDetails = () => {
        setLoading(true);
        axios
            .put(
                `${VS_PORTAL_API_URL}/collections/${collectionId}`,
                {
                    name: formData.collectionName,
                    description: formData.collectionName,
                    businessId: businessId,
                    isPublic: isEmbed,
                },
                { headers: headers() }
            )
            .then((response: any) => {
                if (response.data.success) {
                    setLoading(false);
                    message.success('Collections Details Updated Successfully');
                    setisModalOpen(false);
                    getCollectionList();
                } else {
                    message.error(response?.data.message);
                    setLoading(false);
                }
            })
            .catch((err: any) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message);
                    setLoading(false);
                }
            });
    };


    const onChangeExternalMessage = (e: CheckboxChangeEvent) => {
        setisEmbed(e.target.checked);
    };

    useEffect(() => {
        getCollectionList()
    }, []);

    const showModal = () => {
        return (
            <div className="modal mt-20 fade show" id="add-collection-popup" data-bs-backdrop="false" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'block', paddingTop: '5rem', paddingRight: '16px' }} aria-modal="true" role="dialog">
                <div className="modal-content add-doc-modal-width">
                    <div className="modal-header add-doc-pop-title">
                        <h5 className="modal-title" id="staticBackdropLabel">{modalType === 'create' ? 'Create Collection' : 'Edit Collection'}</h5>
                        <a onClick={() => setisModalOpen(false)} data-bs-dismiss="modal" aria-label="Close" className="add-doc-closed-pop">
                            <img src={Close} />
                        </a>
                    </div>

                    <div className="collection d-flex flex-column align-items-center justify-content-center">

                        <img src={vectorImgPath} alt="icon" title="collection icon  " className="mb-3 mt-4 " />
                        <p className="col-11 text-center">A collection is a collection of documents that VoiceSphere uses to create a knowledgebase. Use it to group documents relevant to a team. Only members invited to the collection can ask questions from that knowledgebase</p>

                    </div>

                    <div className="modal-body">
                        <form onSubmit={(event) => { modalType === 'create' ? onSubmit(event) : onUpdate(event) }}>
                            <div className="mb-3">
                                <label htmlFor="company" className="form-label ">Name </label>
                                <input ref={collectionNameRef} type="text" required name="company" className="form-control bg-color" placeholder="Collection Name" value={formData.collectionName} onChange={(event) => onChange('collectionName', event.target.value)} />
                            </div>


                            <div style={{ marginLeft: '8px', marginTop: '10px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                <Checkbox onChange={onChangeExternalMessage} checked={isEmbed}>
                                    <span style={{ fontWeight: '400', fontSize: '16px', color: '#1A344D' }}>
                                        {isEmbed ? 'Embed' : 'Embed'}
                                    </span>
                                </Checkbox>

                                <Tooltip color={'#758AF0'} title="If the collection has the embed option enabled, you can display the collection on your website by embedding."><img src={help_icon} className="mt-1" style={{ width: '20px', height: '30px', cursor: 'pointer' }} alt="" /></Tooltip>
                            </div>


                            {!!formData.error &&
                                <><Space>
                                    <Alert message={formData.error} type="error" showIcon />
                                </Space><br></br></>
                            }

                            <button type="submit" className="btn btn-voice-submit mb-3">{modalType === 'create' ? 'Create Collection' : 'Save Collection'}</button>
                        </form>
                    </div>
                </div><div className="modal-dialog modal-dialog-centered">
                </div>
            </div>
        )
    }

    const handleGoBack = () => {
        navigate(`/organizations`);
    }

    return (
        <><div style={{
            width: '100%',
            backgroundColor: '#F3F5F7'
          }} key={'document-parent-container'}>
            <Header></Header>
            <Spin tip="Loading" size="large" spinning={loading}>
                <section className="collectioName add-document add-org">
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 org-heading text-start">
                                <Space direction="horizontal">
                                    <a onClick={() => handleGoBack()}> <img src={BackIcon} /></a>
                                    <h1 style={{ fontSize: '24px' }} className="mt-2">
                                        Collections
                                    </h1>
                                </Space>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 add-org-heading text-end anchor-margin">
                                {isAdmin(businessId) && <a onClick={() => handleCreateCollection()} className="add-doc-top-btn ms-auto add-org-btn" data-bs-toggle="modal"
                                    data-bs-target="#add-collection-popup">Add Collection</a>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="collectionForm modal-dialog-scrollable">
                    <div className="container-fluid table-list">
                        <div className="container-fluid content-container">
                            <div className="container">
                                <CollectionsTable businessId={routeParams.businessId} isAdmin={isAdmin(businessId)} handleEditCollection={(txt: any) => handleEditCollection(txt)} searchString={searchString} isModalOpen={isModalOpen}></CollectionsTable>
                            </div>
                        </div>
                    </div>
                </section>
            </Spin>

            {isModalOpen && showModal()}
            <Footer />
            </div>
        </>

    );
};

export default CollectionList;