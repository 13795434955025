/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popconfirm, Space, Spin, message } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import InviteUser from "../components/InviteUser/InviteUser";
import Header from "../components/header/Header";
import SubHeader from "../components/header/SubHeader";
import { headers } from "../utills/axios";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { isAdmin } from "../utills/global";
import { deleteImgPath } from "../utills/images";

const UserList = () => {
  const routeParams = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false as boolean);
  const bottomRef = useRef(null) as any

  const collectionId = routeParams.collectionId;
  const businessId = routeParams.businessId as any;

  const [usersList, setUsersList] = useState([] as any);
  const [usersCount, setUsersCount] = useState(0);
  const [showmodal, setshowmodal] = useState(false)

  const getUsersList = () => {
    setLoading(true)
    setUsersList([])
    setUsersCount(0)
    axios
      .get(`${VS_PORTAL_API_URL}/collections/${collectionId}/userPermissions`, { headers: headers() })
      .then((response: {
        data: {
          objects: any; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          if (response.data.objects) {
            setUsersList(response.data.objects.userJson)
            setUsersCount(response.data.objects.count)
          }
          setLoading(false)

        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }


  const deleteUser = (formItem: any) => {
    const deleteData: any = [{
      roleId: formItem.roleId,
      userId: formItem.userId,
    }
    ]
    axios.delete(`${VS_PORTAL_API_URL}/collections/${collectionId}/userPermissions`, { headers: headers(), data: deleteData })
      .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
        if (response.data.success) {
          message.success('User Deleted Succcessfully');
          getUsersList();
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }

  const updateUser = (userId: any, roleId: string) => {
    const userData: any = [{
      roleId,
      userId
    }]

    axios.put(`${VS_PORTAL_API_URL}/collections/${collectionId}/userPermissions`, userData, { headers: headers() })
      .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
        if (response.data.success) {
          message.success('User Updated Succcessfully');
          getUsersList();
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }

  useEffect(() => {
    getUsersList()
  }, []);


  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [usersList]);

  return (
    <div style={{
      width: '100%',
      backgroundColor: '#F3F5F7',
      minHeight: '100vh'
    }} key={'document-parent-container'}>
      <Header></Header>
      <Spin tip="Loading" size="large" spinning={false}>
        <SubHeader showAnalytics={true}></SubHeader>
        <section id="addDocumentSection">
          <div className="container mt-4 mb-4">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex user-title ads-title heading">
                <a href="#.">
                  <h1>Users<span> ({usersCount})</span></h1>
                </a>
              </div>
              {isAdmin(businessId) && (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 add-org-heading">
                  <a
                    onClick={() => setshowmodal(true)}
                    style={{ width: '22%', marginLeft: 'auto' }}
                    className="add-doc-top-btn ps-butn"
                    data-bs-toggle="modal"
                    data-bs-target="#add-org-popup"
                  >
                    Add User
                  </a>
                </div>
              )}


            </div>
          </div>
        </section>
        <section className="collectionForm">
          <div className="container">
            <div className="row">
              <div className="d-flex justify-content-between  mb-3 flex-wrap">
                <div className="col-lg-4 col-sm-6 col-md-6 col-12 document user"><p className="user-heading-text">User</p></div>
                <div className="col-lg-4 col-sm-4 col-md-4 col-12 permission"><p className="user-heading-text">Permission</p></div>
                <div className="col-lg-2 col-sm-2 col-md-2 col-12 date-padding"><p className="date-up date-added user-heading-text">Date Added</p></div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex justify-content-between  mb-3 flex-wrap">
                {
                  usersList.map((formItem: any, index: any) => {
                    const imageStyle = {
                      width: '35px',
                      height: '35px',
                      borderRadius: '50%',
                      backgroundColor: '#F3F5F7',
                    };
                    const nameStyle = {
                      marginLeft: '16px',
                      marginTop: '16px',
                    };

                    return (
                      <div className="formcollection-inner mb-2" key={'row_' + index}>
                        <div
                          className="d-flex align-items-center col-lg-5 col-md-4 col-sm-4 col-12 orgRow-left"
                        >
                          <div
                            className="rounded-circle d-flex align-items-center justify-content-center"
                            style={imageStyle}
                          >
                            <span className="first-letter">{formItem.userName.charAt(0).toUpperCase()}</span>
                          </div>
                          <div style={{ ...nameStyle, whiteSpace: 'nowrap' }}>
                            <div className="col-lg-8 col-8">
                              <h1 className="mb-3">{formItem.userName}</h1>
                            </div>
                          </div>
                        </div>
                        {!isAdmin(businessId) &&
                          <div className="collect d-flex me-auto">
                            <div className="text-center userType">
                              <p>{formItem.roleId == 1 ? 'Admin' : formItem.roleId == 2 ? 'Editor' : 'Viewer'}</p>
                            </div>
                          </div>
                        }
                        {isAdmin(businessId) &&
                          <div className="collect d-flex me-auto">
                            <div className="collect-field">
                              <input type="radio" name={'roleId_' + index} value="2" checked={formItem.roleId == 1} onChange={(event) => updateUser(formItem.userId, event.target.value)} />
                              <label>Admin</label>
                            </div>
                            <div className="collect-field">
                              <input type="radio" name={'roleId_' + index} value="2" checked={formItem.roleId == 2} onChange={(event) => updateUser(formItem.userId, event.target.value)} />
                              <label>Editor</label>
                            </div>
                            <div className="collect-field">
                              <input type="radio" name={'roleId_' + index} value="3" checked={formItem.roleId == 3} onChange={(event) => updateUser(formItem.userId, event.target.value)} />
                              <label>Viewer</label>
                            </div>
                          </div>
                        }
                        {isAdmin(businessId) &&
                          <div className="delete">
                            <Space>
                              <p className="mt-3">{moment(formItem.createdAt).format('MMM DD, YYYY')}</p>
                              <Popconfirm
                                placement="right"
                                title={'Are you sure to delete this user?'}
                                description={''}
                                onConfirm={() => deleteUser(formItem)}
                                okText="Yes"
                                cancelText="No"
                              >

                                <a href="#.">

                                  <img src={deleteImgPath} title="delete" alt="delete" />
                                </a>
                              </Popconfirm>
                            </Space>
                          </div>
                        }
                        {!isAdmin(businessId) &&
                          <div className="delete">
                            <Space>
                              <p className="mt-3">{moment(formItem.createdAt).format('MMM DD, YYYY')}</p>
                            </Space>
                          </div>
                        }
                      </div>)
                  })
                }

              </div>
            </div>
          </div>
        </section>

      </Spin>
      {showmodal && <InviteUser modalClose={() => setshowmodal(false)} collectionId={collectionId} businessId={businessId} />}
    </div>
  );
};

export default UserList;
