import { Space } from "antd";
import React from "react";
import { logoImgPath } from "../../utills/images";
import { VS_BLOG_URL, VS_PRIVACY_POLICY_URL, VS_TERM_OF_SERVICES_URL } from "../../utills/constants";

const Footer = () => {
  return (
    <div className="footer-div">
      <div className="align-items-center row">
        <div className="col-4">
          <div className="logo">
            <Space className="d-block" direction="horizontal">
              <a href="/">
                <img
                  className="img-fluid"
                  src={logoImgPath}
                  style={{ marginLeft: "3rem", objectFit:'fill',alignSelf:'center' }}
                  alt="logo"
                  title="VoiceSphere"
                />
              </a>
            </Space>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end">
          <a href={VS_BLOG_URL} target="_blank" rel="noreferrer" className="mx-3 footer-text">Blogs</a>
          <a href={VS_TERM_OF_SERVICES_URL} target="_blank" rel="noreferrer" className="mx-3 footer-text">Terms Of Service</a>
          <a href={VS_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer" className="mx-3 footer-text">Privacy Policy</a>
          </div>
        </div>
        <div className="col-2 footer-end-text" >
          @ 2023 VoiceSphere
          </div>
      </div>
    </div>
  );
};

export default Footer;
