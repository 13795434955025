/* eslint-disable @typescript-eslint/no-explicit-any */
export const getStorage = (key: string) => {
    if (sessionStorage.getItem('isFromIFrameSession') === 'true') {
        return sessionStorage.getItem(key);
    } else {
        return localStorage.getItem(key);
    }
}

export const setStorage = (key: string, value: any) => {
    if (sessionStorage.getItem('isFromIFrameSession') === 'true') {
        sessionStorage.setItem(key, value);
    } else {
        localStorage.setItem(key, value);
    }
}

export const removeStorage = (key: string) => {
    if (sessionStorage.getItem('isFromIFrameSession') === 'true') {
        sessionStorage.removeItem(key);
    } else {
        localStorage.removeItem(key);
    }
}

export const clearStorage = () => {
    if (sessionStorage.getItem('isFromIFrameSession') === 'true') {
        sessionStorage.clear();
    } else {
        localStorage.clear();
    }
}