/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint: Missing "key" prop for element in iterator  react/jsx-key */
import { Button, Checkbox, Col, Dropdown, Form, Image, Input, MenuProps, Popover, Row, Space, Spin, Tooltip, message } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import ReactMarkdown from "react-markdown";
import { useParams, useLocation } from "react-router-dom";
import { CloseSquareOutlined } from '@ant-design/icons';
import {
    EmailIcon,
    EmailShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import Loader from "../components/loader/Loader";
import ShareIcon from "../images/Share.svg";
import BlankChatIcon from "../images/blank_chat.svg";
import help_icon from "../images/helpIcon.svg";
import vs_logo from "../images/vs-logo.svg";
import { headers } from "../utills/axios";
import { VS_DEFAULT_ORG_IMAGE, VS_PORTAL_API_URL } from "../utills/constants";
import { getIframeSessionVariable, getUserStorageType, getUserToken, setCurrentUserData } from "../utills/global";
import { CopyIcon, helpfulWhiteImgPath, likeImgPath, logoImgPath, micImgPath, noteSmallImgPath, noteWhiteImgPath, playImgPath } from "../utills/images";
import socket from "../utills/socket";
import { getStorage, removeStorage, setStorage } from "../utills/storage";
import { v4 as uuidv4 } from 'uuid';
import AdobeViewer from "../AdobeViewer";


const IframeChat = () => {

const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    const routeParams = useParams();

    const inputRef = useRef(null) as any

    const bottomRef = useRef(null) as any;

    const [loading, setLoading] = useState(false as boolean);

    const [messageLoading, setMessageLoading] = useState(false as boolean);

    const [chatId, setChatId] = useState(routeParams.chatId || getStorage('chatId') || '' as any);

    const [chatInitiated, setChatInitiated] = useState(false as boolean);

    const [isOutsideContext, setIsOutsideContext] = useState(false as any);

    const [sharedText, setSharedText] = useState('');

    const collectionId = routeParams.collectionId || getStorage(`${getUserStorageType()}collectionId`);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const documentId = Number(queryParams.get('documentId'));

    const [messageList, setMessageList] = useState([] as string[]);

    const businessImageUrl = getStorage(`${getUserStorageType()}businessImageUrl`) || '';

    const [autoScroll, setAutoScroll] = useState(true);

    const [isFromIframe, setIsFromIframe] = useState(false);

    const [suggestionQuestionList, setSuggestionQuestionList] = useState([] as string[]);

    const [isAudioRecordedCompleted, setIsAudioRecordedCompleted] = useState(false as boolean);

    const [streamingMessage, setStreamingMessage] = useState([] as string[])

    const [messageNotes, setMessageNotes] = useState("" as string)

    const [saveMessageLoading, setSaveMessageLoading] = useState(false as boolean);

    const [messageSent, setMessageSent] = useState(false);

    const [windowHeight, setWindowHeight] = useState('');

    const businessName = getStorage(`${getUserStorageType()}businessName`) as any;

    const collectionName = getStorage(`${getUserStorageType()}collectionName`);

    const recorder = useAudioRecorder();

    const imageUrls: any = getStorage(`${getUserStorageType()}imageUrls`) != undefined ? JSON.parse(getStorage(`${getUserStorageType()}imageUrls`) as any) : [];

    const defaultImage = imageUrls?.length ? imageUrls?.[0] : VS_DEFAULT_ORG_IMAGE;

    const [profileImage, setProfileImage] = useState(defaultImage)

    const [showDocumentContainer, setShowDocumentContainer] = useState(false);

    const [pdfViewerLoading, setPdfViewerLoading] = useState(false as boolean);

    const [referenceDocumentId, setReferenceDocumentId] = useState('') as any;

    const [referencePageNumber, setReferencePageNumber] = useState('') as any;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pdfDocumentResponse, setPdfDocumentResponse] = useState({} as any);

    const containerRef: any = useRef(null);

    const chatContainerHeight = !getStorage('isFromRouter') ? 139 : 76;

    const footerClassName = !getStorage('isFromRouter') ? chatId ? `footer send-box sticky-footer-full-width-chat` : 'footer send-box sticky-footer-full-width-no-chat' : showDocumentContainer ? 'footer send-box sticky-footer-with-preview' : 'footer send-box sticky-footer'

    const imageStyle = {
        width: '45px',
        height: 'auto',
    };

    const altText = businessImageUrl ? "" : businessName?.charAt(0)?.toUpperCase();

    const sendMessage = (defaultQuestion?: any) => {
        setMessageSent(true);
        if (!chatId) {
            createChat(true, undefined, defaultQuestion)
        } else {
            if (formData.message || defaultQuestion) {
                createMessages(undefined, defaultQuestion)
            }
        }
    }


    const [formData, setFormData] = useState({
        message: '' as string,
        error: '' as string
    } as any)

    const onChange = (field: string, value: any) => {
        formData[field] = value;
        setFormData({ ...formData })
    }


    const createChat = (isFromSend?: boolean, recordingBlob?: any, defaultQuestion?: any) => {
        setLoading(true)
        const payLoad = {
            'collectionId': collectionId,
            'title': 'New Chat',
            'documentId': documentId
        }
        axios
            .post(`${VS_PORTAL_API_URL}/chats`, payLoad, { headers: headers() })
            .then(async (response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setChatId(response.data.objects.id)
                    socket.emit("joinChat", response.data.objects.id);
                    if (isFromSend) {
                        createMessages(response.data.objects.id, defaultQuestion);
                    }
                    if (recordingBlob) {
                        uploadAudio(recordingBlob, response.data.objects.id)
                    }
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const createMessages = (createdChatId?: string, defaultQuestion?: string) => {
        const currentChatId = chatId || createdChatId;
        setLoading(true)
        const payLoad = {
            'message': formData.message || defaultQuestion
        }
        axios
            .post(`${VS_PORTAL_API_URL}/messages/chats/${currentChatId}`, payLoad, { headers: headers() })
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    if (socket) {
                        socket.emit("sendMessage", response.data.objects.id);
                    }
                    onChange('message', '')
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const handleAnonymousLogin = () => {
        sessionStorage.clear();
        setLoading(true)
        setStorage(`iframeRouterCollectionId`, collectionId);
        sessionStorage.setItem('isFromIFrameSession', 'true')
        const payLoad = {
            'collectionId': collectionId,
            'email': `anonymous_${uuidv4()}@example.com`
        }
        axios
            .post(`${VS_PORTAL_API_URL}/users/anonymous_login`, payLoad)
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setStorage(`iframeRouterUserId`, response.data.objects.id);
                    setStorage(`${getIframeSessionVariable()}token`, response.data.objects.token);
                    setStorage(`${getIframeSessionVariable()}refreshToken`, response.data.objects.refreshToken);
                    setStorage(`${getIframeSessionVariable()}userId`, response.data.objects.id);
                    setCurrentUserData();
                    handleNewChat();
                    setIsFromIframe(true)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });

    }

    const getProfileImageUrls = () => {
        return getStorage(`${getUserStorageType()}imageUrls`);
    }

    useEffect(() => {
        const imageUrls: any = JSON.parse(getProfileImageUrls() as any);
        if (imageUrls?.length) {
            setProfileImage(imageUrls[0])
        }

    }, []);


    useEffect(() => {
        inputRef.current.focus();
    }, [chatInitiated, messageList]);


    useEffect(() => {
        if (chatId && getUserToken() !== null && getUserToken() !== undefined) {
            getMessagesList(chatId);
        }
    }, [chatId]);

    window.onresize = function () {
        setWindowHeight((window.innerHeight - chatContainerHeight) + 'px')
    };

    window.onload = function () {
        setWindowHeight((window.innerHeight - chatContainerHeight) + 'px')
    };



    useEffect(() => {
        if (isFromIframe && chatId) {
            setIsFromIframe(false)
            setLoading(false)
            if(documentId>0){
                window.location.href = `${window.location.origin}/embediframe/${collectionId}/${chatId}?documentId=${documentId}`;    
            } else {
                window.location.href = `${window.location.origin}/embediframe/${collectionId}/${chatId}`;
            }

        }
    }, [isFromIframe, chatId]);

    useEffect(() => {
        if (!!collectionId && getUserToken() !== null && getUserToken() !== undefined) {
            setChatId('');
            setTimeout(() => getSuggestionQuestionsList(), 300);
        }
    }, [collectionId]);

    useEffect(() => {
        if (getStorage('newChatInitiated')) {
            setChatInitiated(!chatInitiated)
            removeStorage('newChatInitiated');
        }
    }, [getStorage('newChatInitiated')]);
    

    useEffect(() => {
        if (getStorage('chatId')) {
            setChatId(getStorage('chatId'))
            socket.emit("joinChat", getStorage('chatId'));
        }
    }, [getStorage('chatId')]);


    useEffect(() => {
        if (getUserToken() !== null && getUserToken() !== undefined) {
            socket.off("receiveMessage").on("receiveMessage", (data) => {
                if (data && !!data.id) {
                    setMessageList((list) => [...list, { ...data }].sort(function (a, b) { return b.id - a.id }));
                }
            });


            socket.on('receiveMessageStream', (streamingData) => {
                setStreamingMessage((list) => [...list, ...streamingData.message])
            });

            socket.off("receiveChatState").on("receiveChatState", handleReceiveChatState)
            socket.off("receiveMessageStreamEnd").on("receiveMessageStreamEnd", handleReceiveMessageStreamEnd)
        }

    }, [socket]);

    useEffect(() => {
        //setShowDocumentContainer(true) 
        if (location.hash) {
            //console.log('location.hash changed', location.hash)
            const locationHashSplit = location.hash.split('#')
            const paramIdSplit = locationHashSplit[1].split("***");
            //console.log(paramIdSplit)
            setReferenceDocumentId(paramIdSplit[0]);
            setReferencePageNumber(paramIdSplit[1]);
        } else {
            setReferenceDocumentId('')
            setReferencePageNumber('')
        }
    }, [location.hash]);
	useEffect(() => { 
        if (referenceDocumentId && referencePageNumber) {
           getPDFDocument();
           setShowDocumentContainer(true)
        } else {
           setShowDocumentContainer(false)
        }
    }, [referenceDocumentId, referencePageNumber]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getPDFDocument = () => {
        removeStorage('pdfUrl')
        removeStorage('pdfFileName')
        setPdfViewerLoading(true)
        axios
            .get(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents/${referenceDocumentId}`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setPdfDocumentResponse(response?.data?.objects || {});
                    if (response?.data?.objects) {
                        setStorage('pdfUrl', response?.data?.objects.url)
                        setStorage('pdfFileName', response?.data?.objects.name)
                    }
                    setPdfViewerLoading(false)
                } else {
                    message.error(response?.data.message)
                    setPdfViewerLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setPdfViewerLoading(false)
                }
            });
    }



    const handleReceiveChatState = (data: any) => {
        if (data.state === 'writing') {
            setMessageLoading(true)
        } else {
            setMessageLoading(false)
        }
    }

    const handleReceiveMessageStreamEnd = () => {
        setStreamingMessage([])
    }

    const handleNewChat = () => {
        setChatInitiated(!chatInitiated)
        createChat()
    }

    useEffect(() => {
        // Send Audio Once Record Completed
        if (isAudioRecordedCompleted) {
            if (!chatId) {
                createChat(false, recorder.recordingBlob)
            } else {
                uploadAudio(recorder.recordingBlob, chatId);
            }
        }
    }, [recorder.recordingBlob]);

    const getMessagesList = (currentChatId: any) => {
        setLoading(true)
        setMessageList([]);
        axios
            .get(`${VS_PORTAL_API_URL}/messages/chats/${currentChatId}`, { headers: headers() })
            .then((response: {
                data: {
                    objects: any[]; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    response?.data?.objects?.map((messageItem: any) => {
                        messageItem.viewHelp = messageItem.reactions && messageItem.reactions.filter((reactionItem: any) => reactionItem.isHelpful === true).length
                        messageItem.viewNote = messageItem.notes?.length > 0
                    })
                    setMessageList(response?.data?.objects || []);
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const getSuggestionQuestionsList = () => {
        setLoading(true)
        setMessageList([]);
        let endpoint = `${VS_PORTAL_API_URL}/collections/${collectionId}`;
        if (documentId) {
            endpoint += `/documents/${documentId}/suggested_questions`;
        } else {
            endpoint += `/suggested_questions`;
        }
        axios
            .get(endpoint, { headers: headers() })
            .then((response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    setSuggestionQuestionList(response.data?.objects)
                    setLoading(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }
const removeLinks = (text : any) => {
    let result = text.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');
    result = result.replace(/<a[^>]*>(.*?)<\/a>/g, '$1');
    result = result.replace(/\b\S+\.pdf\(Page \d+\)/g, '');
    result = result.replace(/https?:\/\/[^\s]+|www\.[^\s]+/g, '');
    result = result.replace(/\(\s*\)/g, '');
    result = result.replace(/,\s*,/g, ',');  
    result = result.replace(/(,\s*)+$/g, ''); 
    return result.trim(); 
};

const copyToClipboard = (message : any) => {
    const plainText = removeLinks(message); 
    navigator.clipboard.writeText(plainText).then(() => {
    }).catch(err => {
        console.error('Failed to copy text: ', err);
    });
};



    const shareUrl = window.location.href;


    // eslint-disable-next-line no-empty-pattern
    const onClick: MenuProps['onClick'] = ({ }) => {
        //message.info(`Click on item ${key}`);
    };

    const items = [
        {
            key: '1',
            label: (
                <Space>
                    <EmailShareButton
                        url={shareUrl}
                        subject={sharedText}
                        body="body"
                    >
                        <EmailIcon size={25} round />
                        <span style={{ marginLeft: 10 }}>Email</span>
                    </EmailShareButton>
                </Space>
            ),
        },
        {
            key: '2',
            label: (
                <Space>
                    <TwitterShareButton
                        url={shareUrl}
                        title={sharedText}
                    >
                        <TwitterIcon size={25} round />
                        <span style={{ marginLeft: 10 }}>Twitter</span>
                    </TwitterShareButton>
                </Space>
            ),
        },
        {
            key: '3',
            label: (
                <Space>
                    <WhatsappShareButton
                        url={shareUrl}
                        title={sharedText}
                        separator=":: "
                    >
                        <WhatsappIcon size={25} round />
                        <span style={{ marginLeft: 10 }}>WhatsApp</span>
                    </WhatsappShareButton>
                </Space>
            ),
        },
    ];

    const updateChat = (isOutsideContext: boolean) => {
        axios.put(`${VS_PORTAL_API_URL}/chats/${chatId}`, {
            isOutsideContext
        }, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (!response.data.success) {
                    message.error(response?.data.message)
                    setLoading(false)
                } 
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const uploadAudio = (audioBlob: any, currentChatId: any) => {
        const formdata = new FormData();
        const audioFile = new File([audioBlob], 'audio.wav');
        formdata.append('audio', audioFile, 'audio.wav');
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/messages/chats/${currentChatId}/audios`, formdata, { headers: { 'Authorization': getUserToken(), "Content-Type": "multipart/form-data" } })
            .then(async (response: {
                data: {
                    objects: any; success: any; message: React.SetStateAction<string>;
                };
            }) => {
                if (response.data.success) {
                    getMessagesList(currentChatId);
                    setLoading(false)
                    setIsAudioRecordedCompleted(false)
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                    setIsAudioRecordedCompleted(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                    setIsAudioRecordedCompleted(false)
                }
            });
    }

    const updateMessageReaction = (isHelpful: boolean, isNotHelpful: boolean, messageId: number) => {
        axios.post(`${VS_PORTAL_API_URL}/messages/${messageId}/reactions`, {
            isHelpful,
            isNotHelpful
        }, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    getMessagesList(chatId);
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setLoading(false)
                }
            });
    }

    const updateMessageNotes = (messageId: number) => {
        setAutoScroll(false);
        setSaveMessageLoading(true)
        axios.post(`${VS_PORTAL_API_URL}/messages/${messageId}/notes`, {
            notes: messageNotes
        }, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    setMessageNotes('')
                    setSaveMessageLoading(false)
                    message.success('Notes Added Successfully')
                    getMessagesList(chatId);
                } else {
                    message.error(response?.data.message)
                    setSaveMessageLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    if (err?.response?.data.message) {
                        message.error(err?.response?.data.message)
                    }
                    setSaveMessageLoading(false)
                }
            });
    }


    const onChangeExternalMessage = (e: CheckboxChangeEvent) => {
        setIsOutsideContext(e.target.checked)
        updateChat(e.target.checked)
    };

    const checkPdfUrl = (message: any) => {
        return message.indexOf('pdf.voicesphere.co') !== -1;
    }


    const generateMessage = (message: any) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return message.replace(urlRegex, function (url: string) {           
            const urlSplit = url?.split('&documentId=');            
            const pdfDocumentId = urlSplit[1]?.split('&page')[0];
            const replacedText = `${collectionId}#${pdfDocumentId}***`;
            return url.indexOf('&page=') !== -1 ? url?.replace('https://', '')
                .replace(/pdf.voicesphere.co.*page=/g, replacedText)
                .replace(/\`/g, '') : url.replace(/\`/g, '');
        })
    }

    const handleViewNote = (messageItem: any) => {
        setAutoScroll(false);
        messageItem.viewNote = true;
        setMessageList((list) => [...list, { ...messageItem }].sort(function (a, b) { return b.id - a.id }));
    }

    const handleViewHelp = (messageItem: any) => {
        setAutoScroll(false);
        messageItem.viewHelp = true;
        updateMessageReaction(true, false, messageItem.id)
        setMessageList((list) => [...list, { ...messageItem }].sort(function (a, b) { return b.id - a.id }));
    }

    useEffect(() => {
        const scrollToBottom = () => {
            const scroll = bottomRef.current;
            if (scroll) {
                if (!getStorage('isFromRouter')) {
                    scroll.scrollTop = scroll.scrollHeight + window.innerHeight;
                } else {
                    scroll.scrollTop = scroll.scrollHeight
                }
            }
        };

        if (autoScroll) {
            setTimeout(scrollToBottom, 0); // Use setTimeout to ensure the DOM has updated
        }
    }, [messageList, messageSent, streamingMessage]); // Dependency array


    const adjustHeight = (element: HTMLTextAreaElement) => {
        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 'px';
    };

    const goToPlugin = () => {
        const url = 'https://voicesphere.co'
        window.open(url, "_blank", "noreferrer");

    }
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const renderContentBasedOnFileType = (): any => {
        return (
            <AdobeViewer fileUrl={pdfDocumentResponse?.url} fileName={pdfDocumentResponse?.name}/>
        );
    };

    const closeCurrentDocument = () => {
        setShowDocumentContainer(false)
        setReferencePageNumber('')
        setReferenceDocumentId('')
    }
    


    useEffect(() => {
        if (inputRef.current) {
            adjustHeight(inputRef.current);
        }
    }, [inputRef, formData.message]);

    useEffect(() => {
        if (!routeParams.chatId && window.location.href.indexOf('/embed/') != -1) {
            handleAnonymousLogin()
        }
    }, []);

    useEffect(() => {
        /** Set Dynamic Title for Embed Page **/
        if (getStorage(`${getUserStorageType()}businessName`) && getStorage(`${getUserStorageType()}collectionName`) && !getStorage('isFromRouter')) {
            document.title = `Chat with ${getStorage(`${getUserStorageType()}businessName`)} - ${getStorage(`${getUserStorageType()}collectionName`)} documents`;
        }
    }, []);




    return (
        <>
        
        <div className="flex-parent-element" style={{
                'height' : '100vh'
            }}>
                    {referenceDocumentId && referencePageNumber ?
                        <><Spin tip="Loading" size="large" spinning={pdfViewerLoading}></Spin><div className="flex-child-element with-border" ref={containerRef} style={{
                            'display': showDocumentContainer ? 'block' : 'none'
                        }}>
                            <CloseSquareOutlined style={{
                                float: 'right',
                                cursor: 'pointer'
                            }} onClick={() => closeCurrentDocument()} />
                            {pdfDocumentResponse && pdfDocumentResponse?.url && renderContentBasedOnFileType()
                                // eslint-disable-next-line react/no-unknown-property
                            }
                            <div id="pdf-div" className="full-window-div"></div>
                        </div></>
                        : ''}
            <div className={referenceDocumentId && referencePageNumber ? 'flex-child-element with-border' : 'flex-child-element'}>
            <div className="iframe-container">
                <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="msg-head">
                            <div className="row">
                                {!getStorage('isFromRouter') &&
                                    <div className="nc-top m-auto iframe-header">
                                        <Row>
                                            <Col span={12} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                {businessImageUrl && businessImageUrl !== "null" ? (
                                                    <img
                                                        src={businessImageUrl}
                                                        className="rounded-circle"
                                                        alt={altText}
                                                        title="Organization"
                                                        style={imageStyle}
                                                    />
                                                ) : (
                                                    <div
                                                        className="rounded-circle d-flex align-items-center justify-content-center"
                                                        style={imageStyle}
                                                    >
                                                        <span className="first-letter">{businessName?.charAt(0)?.toUpperCase()}</span>
                                                    </div>
                                                )}

                                                <Space direction="vertical" size="small" style={{ lineHeight: '1', marginLeft: '16px', paddingTop: '0px' }}>
                                                    <div style={{ fontSize: '20px', fontWeight: 'bold', textTransform: 'capitalize' }}>{businessName}</div>
                                                    <div style={{ fontSize: '15px' }}>{collectionName}</div>
                                                </Space>
                                            </Col>
                                            <Col span={11} style={{
                                                textAlign: 'right'
                                            }}>
                                                <Space onClick={goToPlugin} style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    <span className="iframe-power-text">powered by</span>
                                                    <img src={logoImgPath} alt="logo" title="VoiceSphere" style={{
                                                        width: '146px'
                                                    }} />
                                                </Space>
                                            </Col>
                                            <Col span={1}>&nbsp;</Col>
                                        </Row>
                                    </div>
                                }
                                <a style={{
                                    'display': 'none'
                                }}
                                    id="newChat" onClick={() => handleNewChat()}>New Chat</a>
                            </div>
                        </div>
                        <Spin tip="Loading" size="large" spinning={loading}></Spin>                       
                        <div id="chatContainer" className="modal-body over-hide">
                            <div className="msg-body" style={{
                               // height: !getStorage('isFromRouter') ? windowHeight : '65vh',
                               'height': !getStorage('isFromRouter') ? `${windowHeight}` : (isMac ? '75vh' : '65vh'),
                                overflowY: 'scroll'
                            }} ref={bottomRef}>
                                <ul className="d-flex flex-column m-auto flex-column-reverse" style={{
                                    'width': '70%'
                                }}>
                                    {!messageList?.length &&
                                        <li>
                                            <div className="msg-body">
                                                <div className="col-12 Ask-your-first-question d-flex  flex-column justify-content-center align-items-center">
                                                    {
                                                        getStorage('isFromRouter') &&
                                                        <img src={BlankChatIcon} alt="Chat" title="Chat Icon" />
                                                    }

                                                    <h6>Ask your first question</h6>
                                                    <p>You can ask questions like:</p>
                                                    <div>{suggestionQuestionList?.map((question, index: any) => {
                                                        return <p style={{
                                                            textAlign: 'left',
                                                            fontStyle: 'italic',
                                                            cursor: 'pointer',
                                                            color: '#758AF0'
                                                        }} onClick={() => sendMessage(question)} key={`suggestion-question-${index}`}>{question}</p>;
                                                    })}</div>
                                                </div>
                                            </div>
                                        </li>}
                                    {streamingMessage && !!streamingMessage.length &&
                                        <li>
                                            <div className="repaly-box">
                                                {streamingMessage}
                                            </div>
                                        </li>}
                                    {_.uniqWith(messageList, (arrVal: any, othVal: any) => arrVal.id === othVal.id)?.filter((item: any) => !!item.id)
                                        .map((item: any, index: any) => {
                                            if (!item.replyToId) {
                                                return (<li className="sender" key={`sender-${index}`}>
                                                    <div className="d-flex flex-row flex-row-reverse justify-content-end">
                                                        <img src={profileImage}
                                                            alt="avatar 1"
                                                            style={{
                                                                'width': '32px',
                                                                'height': '32px',
                                                                'marginRight': '17px'
                                                            }} />
                                                        <div className="text-wrap">
                                                            <p className="small p-3 me-3 mb-1 chat-answer-font-size ">{item.message}</p>
                                                        </div>
                                                    </div>
                                                </li>);
                                            }
                                            if (item.replyToId) {
                                                return (<li className="repaly" key={`replay-${index}`}>
                                                    <div className="d-flex flex-row justify-content-end">
                                                        <img src={businessImageUrl && businessImageUrl !== "null" ? businessImageUrl: vs_logo}
                                                            alt="avatar 1" style={{
                                                                'width': '32px',
                                                                'height': '32px'
                                                            }} />
                                                        <div className="repaly-box">                                                            
                                                            {
                                                                        checkPdfUrl(item.message) ?
                                                                            <ReactMarkdown
                                                                                //linkTarget="_blank"
                                                                                components={{
                                                                                    img: (props: any) => (
                                                                                        <Image src={props.src} alt={props.alt} className="reply-message-img" />
                                                                                    ),
                                                                                }}
                                                                            >
                                                                                {generateMessage(item.message)}
                                                                            </ReactMarkdown>
                                                                            :
                                                                            <ReactMarkdown
                                                                                linkTarget="_blank"
                                                                                components={{
                                                                                    img: (props: any) => (
                                                                                        <Image src={props.src} alt={props.alt} className="reply-message-img" />
                                                                                    ),
                                                                                }}
                                                                            >
                                                                                {generateMessage(item.message)}
                                                                            </ReactMarkdown>
                                                                    }
                                                            <Space style={{ float: 'right' }}>
                                                                <Popover placement="topRight" destroyTooltipOnHide={true} content={
                                                                    <>
                                                                        <div className="notes-list">
                                                                            {
                                                                                item.notes?.map((subitem: any, index: any) => {
                                                                                    return (
                                                                                        <><Row>
                                                                                            <Col span={24}><span>{subitem.notes}</span></Col>
                                                                                        </Row>
                                                                                            <Row className={index < item.notes.length - 1 ? 'today' : 'today today-border-none'}>
                                                                                                <Col span={12}></Col>
                                                                                                <Col span={12} style={{
                                                                                                    textAlign: 'end'
                                                                                                }}>{subitem.updatedAt ? moment(subitem.updatedAt).format('MMM DD, YYYY') : ''}</Col>
                                                                                            </Row></>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        <Form layout="vertical" hideRequiredMark>
                                                                            <Row gutter={16}>
                                                                                <Col span={24}>
                                                                                    <Input.TextArea rows={4} cols={50} placeholder="Type a note" style={{
                                                                                        background: '#f5f5f5',
                                                                                        resize: 'none',
                                                                                        marginBottom: 8
                                                                                    }} onChange={(e) => setMessageNotes(e.target.value)} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row gutter={16}>
                                                                                <Col span={12}></Col>
                                                                                <Col span={12} style={{
                                                                                    textAlign: 'right'
                                                                                }}><Button type="primary" style={{
                                                                                    background: '#758AF0'
                                                                                }} onClick={() => updateMessageNotes(item.id)} loading={saveMessageLoading}>Add</Button></Col>
                                                                            </Row>
                                                                        </Form>
                                                                    </>
                                                                } trigger="click">
                                                                    {item?.notes?.length > 0 ?
                                                                        <Button type="primary" className="chat-list-btn">
                                                                            <Space>
                                                                                <img className="chat-list-icon-img" src={noteWhiteImgPath} alt="View note" style={{ cursor: 'pointer', width: 18, height: 18 }} title="View note" onClick={() => {
                                                                                    copyToClipboard(item.message)
                                                                                }} />
                                                                                <span>View note</span>
                                                                            </Space>
                                                                        </Button>
                                                                        :
                                                                        getStorage('isFromRouter') &&
                                                                        <img src={noteSmallImgPath} alt="Note" style={{ cursor: 'pointer', width: 24, height: 24 }} title={'Add note'} onClick={() => {
                                                                            handleViewNote(item)
                                                                        }} />
                                                                    }
                                                                </Popover>
                                                                {!item.viewHelp && <img src={likeImgPath} alt="Help" style={{ cursor: 'pointer', width: 24, height: 24 }} title="Helpful" onClick={() => handleViewHelp(item)} />}
                                                                {item.viewHelp && <Button type="primary" className="chat-list-btn">
                                                                    <Space>
                                                                        <img src={helpfulWhiteImgPath} alt="Copy" style={{ cursor: 'pointer', width: 18, height: 18 }} title="Helpful" onClick={() => copyToClipboard(item.message)} />
                                                                        <span>Helpful</span>
                                                                    </Space>
                                                                </Button>}
                                                            </Space>
                                                        </div>
                                                        <Space direction="vertical">
                                                            <img src={CopyIcon} alt="Copy" style={{ cursor: 'pointer' }} title="Copy Message" onClick={() => copyToClipboard(item.message)} />
                                                            <Dropdown menu={{ items, onClick }} placement="bottomLeft">
                                                                <img onClick={(e) => {
                                                                    setSharedText(item.message);
                                                                    e.preventDefault();
                                                                }} src={ShareIcon} alt="Share" style={{ cursor: 'pointer' }} title="Share Message" />
                                                            </Dropdown>
                                                        </Space>
                                                    </div>
                                                </li>);
                                            }
                                        })}


                                </ul>
                            </div>
                        </div>
                        {messageLoading &&
                            <Loader></Loader>}
                        <>

                           
                        </>
                    </div>
                    
                </div>
                <div  className={footerClassName}>
                                <div className="col-8 input-chat-mic bg-white p-3 d-flex m-auto"  style={{
                                width: '100%'
                            }}>
                                    {recorder.isRecording &&
                                        <input type="image" src={playImgPath} alt="Submit" width="100" height="44" onClick={() => {
                                            recorder.stopRecording();
                                            setIsAudioRecordedCompleted(true);
                                        }}></input>}
                                    {!recorder.isRecording &&
                                        <input type="image" src={micImgPath} alt="Submit" width="100" height="44" onClick={() => {
                                            recorder.startRecording();
                                            setIsAudioRecordedCompleted(false);
                                        }}></input>}
                                    {recorder.isRecording &&
                                        <div style={{ width: '100%' }}>
                                            <AudioRecorder
                                                audioTrackConstraints={{
                                                    noiseSuppression: true,
                                                    echoCancellation: true,
                                                }}
                                                recorderControls={recorder}
                                                showVisualizer={true} />
                                            {chatId && getStorage('isFromRouter') &&
                                                <div style={{ marginLeft: '20px', marginTop: '6px', display: 'flex', alignItems: 'center' }}>
                                                    <Checkbox onChange={onChangeExternalMessage} checked={isOutsideContext}><span style={{ fontWeight: '400', fontSize: '16px', color: '#1A344D' }}>Use external knowledge</span></Checkbox>
                                                    <Tooltip color={'#758AF0'} title="Let VoiceSphere use knowledge gained from sources other than your documents to answer your questions"><img src={help_icon} className="mt-1" style={{ width: '20px', height: '20px', cursor: 'pointer' }} alt="" /> </Tooltip>
                                                </div>}
                                        </div>}
                                    <div style={{ display: recorder.isRecording ? 'none' : 'block', width: ' 85%' }}>
                                        <textarea
                                            ref={inputRef}
                                            value={formData.message}
                                            className="form-control mic-text-input"
                                            placeholder="Type your question"
                                            onChange={(event) => onChange('message', event.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && !e.shiftKey) {
                                                    e.preventDefault();
                                                    sendMessage();
                                                }
                                            }}
                                            style={{
                                                height: '44px'
                                            }}
                                        />
                                        {chatId &&  getStorage('isFromRouter') &&
                                            <div style={{ marginLeft: '20px', marginTop: '6px', display: 'flex', alignItems: 'center' }}>
                                                <Checkbox onChange={onChangeExternalMessage} checked={isOutsideContext}><span style={{ fontWeight: '400', fontSize: '16px', color: '#1A344D' }}>Use external knowledge</span></Checkbox>
                                                <Tooltip color={'#758AF0'} title="Let VoiceSphere use knowledge gained from sources other than your documents to answer your questions"><img src={help_icon} className="mt-1" style={{ width: '20px', height: '20px', cursor: 'pointer' }} alt="" /> </Tooltip>
                                            </div>}
                                    </div>

                                    <span className="btn-submit" style={{ display: recorder.isRecording ? 'none' : 'block', cursor: 'pointer', left: 12 }} onClick={() => { sendMessage(); }}>
                                        <input type="button" value="Send" className="btn send-btn" />
                                    </span>
                                </div>
                            </div>
            </div>  
            </div>
        </div>                                   
        </>
    );
};

export default IframeChat;