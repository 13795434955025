/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { getStorage, setStorage } from "../utills/storage";
import { headers } from "../utills/axios";
import { Popconfirm, Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import Close from '../images/Close.png'
import Rectangle272 from "../images/Rectangle272.svg"
import { addImgPath, deleteImgPath } from "../utills/images";
import { LoadingOutlined } from '@ant-design/icons';
import Footer from "../components/footer/Footer";
import { isAdmin, setCurrentUserData } from "../utills/global";



const OrganizationList = () => {

  const navigate = useNavigate();

  const businessNameRef = useRef() as any;
  const businessTypeRef = useRef(null) as any;
  const locationRef = useRef(null) as any;

  const [numberOfOrganizations, setNumberOfOrganizations] = useState(0);

  const [loading, setLoading] = useState(false as boolean);
  const [isReload, setisReload] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [modalType, setmodalType] = useState('create' as string);
  const [buisnessId, setbuisnessId] = useState('' as string);
  const [ProfileImg, setProfileImg] = useState(null as any);


  const [formData, setFormData] = useState({
    businessName: '' as string,
    error: '' as string,
    businessType: '' as string,
    location: '' as string,
    image: '' as string,
  } as any)

  const [organizationList, setOrganizationList] = useState([] as any);


  const userId = getStorage('userId');

  const navigateToCollections = (businessId: any, businessName: string, imageUrl: string) => {
    setStorage('businessName', businessName);
    setStorage('businessId', businessId);
    setLoading(false)
    setStorage('businessImageUrl', imageUrl == null ? "" : imageUrl);
    navigate(`/collections/${businessId}`);
  }

  const onChange = (field: string, value: any) => {
    formData[field] = value;
    setFormData({ ...formData })
  }

  const handleCreateBusiness = () => {
    setFormData({
      businessName: '' as string,
      error: '' as string,
      businessType: '' as string,
      location: '' as string,
      image: null as any,
    })
    setisModalOpen(true)
    setTimeout(() => businessNameRef.current.focus(), 300);
    setmodalType('create')
    setProfileImg(null)
  }

  const handleEditBusiness = (item: any) => {
    setFormData({ businessName: item?.name, businessType: item?.type, location: item?.location, image: item?.imageUrl })
    setbuisnessId(item.id)
    setmodalType('update')
    setisModalOpen(true)
    setTimeout(() => businessNameRef.current.focus(), 300);
    setProfileImg(null)
  }

  const handleChangeProfile = (e: any) => {
    setFormData((prev: any) => ({ ...prev, image: URL.createObjectURL(e.target.files[0]) }));
    setProfileImg(e.target.files[0]);
  };

  const formValidation = () => {
    return true;
  }

  const updateOrganisation = () => {
    axios.put(`${VS_PORTAL_API_URL}/businesses/${buisnessId}`, {
      'name': formData.businessName,
      'type': formData.businessType,
      'location': formData.location,
    }, { headers: headers() })
      .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
        if (response.data.success) {
          if (ProfileImg) {
            uploadImage(buisnessId)
          } else {
            message.success('Businsess Details Updated Successfully')
            setisModalOpen(false)
            setLoading(false)
            setisReload(!isReload)
          }
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }

  const uploadImage = (businessId: any) => {
    const formdata = new FormData();
    formdata.append('file', ProfileImg);

    setLoading(true)
    axios
      .post(`${VS_PORTAL_API_URL}/businesses/${businessId}/images`, formdata, {
        headers: {
          'Authorization': getStorage('token'),
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: {
        data: {
          [x: string]: any; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          message.success('Businsess Details Updated Successfully')
          setisModalOpen(false)
          setLoading(false)
          setisReload(!isReload)
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          message.error(err?.response?.data.message)
          setLoading(false)
        }
      }
      );
  }


  
  const updateBusinessDetails = () => {
    setLoading(true)
    axios
      .post(`${VS_PORTAL_API_URL}/businesses`, {
        'name': formData.businessName,
        'type': formData.businessType,
        'location': formData.location,
      }, { headers: headers() })
      .then((response: {
        data: {
          [x: string]: any; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {

          setStorage('businessId', response.data.objects.id);
          if (ProfileImg) {
            uploadImage(response.data?.objects?.id)
          } else {
            message.success('Businsess Details Added Successfully')
            setisModalOpen(false)
            setLoading(false)
            setisReload(!isReload)
          }

        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }

  const onSubmit = (event: any) => {
    if (formValidation()) {
      formData.error = '';
      setFormData(formData);
      updateBusinessDetails()
      setCurrentUserData()
    }
    event.preventDefault();
    return false;
  }

  const onUpdate = (event: any) => {
    if (formValidation()) {
      formData.error = '';
      setFormData(formData);
      setLoading(true)
      updateOrganisation();
    }
    event.preventDefault();
    return false;
  }

  const getOrganizationList = () => {
    setLoading(true);
    setOrganizationList([]);

    axios
      .get(`${VS_PORTAL_API_URL}/users/${userId}/businesses`, { headers: headers() })
      .then((response: {
        data: {
          objects: any[];
          success: any;
          message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          if (response.data.objects && response.data.objects?.length) {
            const numberOfOrgs = response.data.objects?.length;
            setNumberOfOrganizations(numberOfOrgs);

            // Create an array to store promises for fetching metrics
            const fetchMetricsPromises = response.data.objects.map(async (businessItem) => {
              const businessImageUrl = businessItem.imageUrl;
              const metricsResponse = await axios.get(
                `${VS_PORTAL_API_URL}/businesses/${businessItem.id}/metrics`,
                { headers: headers() }
              );

              if (metricsResponse.data.success) {
                const metricsData: any = metricsResponse.data.objects || [];
                businessItem.collections = metricsData?.collections || 0;
                businessItem.documents = metricsData?.documents || 0;
                businessItem.users = metricsData?.users || 0;
                businessItem.messages = metricsData?.messages || 0;
                businessItem.image = businessImageUrl;
                return { ...businessItem };
              } else {
                message.error(metricsResponse?.data.message);
                return null;
              }
            });

            // Wait for all promises to resolve
            Promise.all(fetchMetricsPromises)
              .then((organizations) => {
                // Filter out any null organizations (failed requests)
                const filteredOrganizations = organizations.filter(Boolean);

                // Sort the organizations by id in descending order
                const sortedOrganizations = filteredOrganizations.sort((a, b) => b.id - a.id);

                setOrganizationList(sortedOrganizations);
                setLoading(false);
              })
              .catch((err) => {
                message.error(err?.response?.data.message);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        } else {
          message.error(response?.data.message);
          setLoading(false);
        }
      })
      .catch((err: { response: { data: { success: any; message: React.SetStateAction<string> } } }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message);
          }
          setLoading(false);
        }
      });
  };

  const deleteOrganization = (businessId: any) => {
    axios.delete(`${VS_PORTAL_API_URL}/businesses/${businessId}`, { headers: headers() })
      .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
        if (response.data.success) {
          message.success('Organization Deleted Succcessfully');
          getOrganizationList();
          setCurrentUserData()
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }


  useEffect(() => {
    getOrganizationList()
    setCurrentUserData()
  }, [isReload]);

  useEffect(() => {
    if (getStorage('businesses') == undefined || getStorage('collections') == undefined || getStorage('businessId') == undefined || getStorage('collectionId') == undefined) {
      setCurrentUserData()
    }
  }, [getStorage('businesses'), getStorage('collections'), getStorage('businessId'), getStorage('collectionId')]);


  const handleKeyPress = (event: any, nextInput: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextInput.current.focus();
    }
  };

  const showModal = () => {
    return (
      <div className="modal mt-20 fade show" id="add-org-popup" data-bs-backdrop="false" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'block', paddingTop: '5rem', paddingRight: '16px' }} aria-modal="true" role="dialog">
        <div className="modal-content add-doc-modal-width">
          <div className="modal-header add-doc-pop-title">
            <h5 className="modal-title" id="staticBackdropLabel">{modalType === 'create' ? 'Create a Organization' : 'Edit Organization'}</h5>
            <a onClick={() => setisModalOpen(false)} data-bs-dismiss="modal" aria-label="Close" className="add-doc-closed-pop">
              <img src={Close} />
            </a>
          </div>
          <div className="modal-body">
            <form onSubmit={(event) => { modalType === 'create' ? onSubmit(event) : onUpdate(event) }} >
              <div className="mb-3">
                <label htmlFor="company" className="form-label ">Name of your organization</label>
                <input ref={businessNameRef} type="text" required name="company" className="form-control bg-color" placeholder="Organization Inc." value={formData.businessName} onChange={(event) => onChange('businessName', event.target.value)} onKeyDown={(event) => handleKeyPress(event, businessTypeRef)} />
              </div>
              <div className="mb-3">
                <label htmlFor="text" className="form-label">Organization type</label>
                <select ref={businessTypeRef} className="form-select form-control bg-color custom-select" required name="buisness" defaultValue={formData.businessType} onChange={(event) => onChange('businessType', event.target.value)} onKeyDown={(event) => handleKeyPress(event, locationRef)}>
                  <option selected></option>
                  <option>Academic Research</option>
                  <option>E-Commerce</option>
                  <option>Education</option>
                  <option>Education consultant</option>
                  <option>Engineering</option>
                  <option>Finance</option>
                  <option>HR Recruitment</option>
                  <option>Information Technology</option>
                  <option>Insurance</option>
                  <option>Real Estate</option>
                  <option>Tech Support</option>
                  <option>Tourism and Hospitality</option>
                  <option>Others</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="location" className="form-label ">Location</label>
                <input ref={locationRef} type="text" required name="location" className="form-control bg-color" placeholder="New Delhi, India" value={formData.location} onChange={(event) => onChange('location', event.target.value)} />
              </div>

              <div className="mb-3">
                <div className=" d-flex  flex-row align-items-center">
                  <div className="col-3 add-icon">
                    <label htmlFor="logoInput" className="circle-button">
                      <input type="file" id="logoInput" style={{ display: 'none' }} onChange={(e) => handleChangeProfile(e)} accept="image/*" />
                      <img src={formData.image ? formData.image : addImgPath} alt="profile"
                        style={{
                          borderRadius: "50%",
                          width: formData.image ? "90px" : '',
                          height: formData.image ? "90px" : '',
                          objectFit: "fill",
                        }}
                      />
                    </label>
                  </div>
                  <div className="col-9 add-heading ">
                    <h6 className="m-0">Your Organization’s Logo (optional)</h6>
                  </div>
                </div>
              </div>
              <button className="btn btn-voice-submit mb-3" type="submit">
                {loading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : modalType === 'create' ? 'Create' : 'Update'}
              </button>
            </form>
          </div>
        </div><div className="modal-dialog modal-dialog-centered">
        </div>
      </div>
    )
  }



  return (
    <>
      <Header></Header>
      <Spin tip="Loading" size="large" spinning={loading}>
        <section className="collectioName add-document add-org">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 org-heading text-start">
                <h1>Organizations <span>({numberOfOrganizations})</span></h1>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 add-org-heading text-end anchor-margin">
                <a onClick={() => handleCreateBusiness()} style={{ width: '32%' }} className="add-doc-top-btn ms-auto ps-butn ml-2" data-bs-toggle="modal"
                  data-bs-target="#add-org-popup">Add Organization</a>
              </div>
            </div>
          </div>
        </section>

        <section id="orgRow" className="content-container table-list" style={{
          left: 24
        }}>
          <div className="container content-container">
            {
              organizationList.map((bitem: any) => {
                const imageStyle = {
                  width: '65px',
                  height: 'auto',
                };

                const nameStyle = {
                  marginLeft: '16px',
                  marginTop: '16px',
                };


                const altText = bitem.imageUrl ? "" : bitem.name.charAt(0).toUpperCase();
                return (
                  <div
                    className="row align-items-center bg-white my-3 orgRight-row "

                    key={`businesses_row_${bitem.id}`}
                  >
                    <div
                      className="d-flex align-items-center col-lg-4 col-md-4 col-sm-4 col-12 orgRow-left"
                      onClick={() => navigateToCollections(bitem.id, bitem.name, bitem.imageUrl)}
                    >
                      {bitem.imageUrl ? (
                        <img
                          src={bitem.imageUrl}
                          className="rounded-circle"
                          alt={altText}
                          title="Organization"
                          style={imageStyle}
                        />
                      ) : (
                        <div
                          className="rounded-circle d-flex align-items-center justify-content-center"                          
                        >
                          <span className="circle">{bitem.name.charAt(0).toUpperCase()}</span>
                        </div>
                      )}
                      <div style={{ ...nameStyle, whiteSpace: 'nowrap' }}>
                        <div className="col-lg-8 col-8">
                          <h1 className="mb-3">{bitem.name}</h1>
                          <p>{bitem.location}</p>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex align-items-center col-lg-4 col-md-5 col-sm-5 col-9 mt-3 orgRow-right"
                      onClick={() => navigateToCollections(bitem.id, bitem.name, bitem.imageUrl)}
                    >
                      <div className="col-lg-8 col-8">
                        <ul
                          className="d-flex align-items-center breakItems"
                          key={`business_list_${bitem.id}`}
                        >
                          <div className="d-flex">
                            <li className="org-li" key={`queries_${bitem.id}`}>
                              <h6>Messages</h6>
                              <h5>{bitem.messages}</h5>
                            </li>
                            <li className="org-li" key={`collections_${bitem.id}`}>
                              <h6>Collections</h6>
                              <h5>{bitem.collections}</h5>
                            </li>
                          </div>
                          <div className="d-flex">
                            <li className="org-li" key={`documents_${bitem.id}`}>
                              <h6>Documents</h6>
                              <h5>{bitem.documents}</h5>
                            </li>
                            <li className="org-li user-icon-margin" key={`users_${bitem.id}`}>
                              <h6>Users</h6>
                              <h5>{bitem.users}</h5>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 text-end action-btn">
                      {isAdmin(bitem.id) && (
                        <div className="icon-row">
                          <a
                            href="#."
                            data-bs-toggle="modal"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditBusiness(bitem);
                            }}
                            data-bs-target="#add-org-popup2"
                            className="icon-margin-right" // Apply the CSS class
                          >
                            <img src={Rectangle272} alt="" key={`business_edit_${bitem.id}`} />
                          </a>
                          <Popconfirm
                            placement="right"
                            title="Are you sure to delete this organization?"
                            onConfirm={() => deleteOrganization(bitem.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <a
                              href="#."
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              key={`business_delete_${bitem.id}`}
                            >
                              <img src={deleteImgPath} alt="" />
                            </a>
                          </Popconfirm>
                        </div>
                      )}
                    </div>
                  </div>
                );





              })
            }




          </div>
        </section>

      </Spin>
      {isModalOpen && showModal()}
      <Footer />
    </>

  );
};

export default OrganizationList;