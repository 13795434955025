/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import "./css/responsive.css";
import "./css/style.css";
import Billing from "./pages/Billing";
import Chat from "./pages/Chat";
import Collection from "./pages/Collection";
import CollectionList from "./pages/CollectionList";
import DocumentList from "./pages/DocumentList";
import Documents from "./pages/Documents";
import IframeChat from "./pages/IframeChat";
import InviteUsers from "./pages/InviteUsers";
import Knowledgebase from "./pages/Knowledgebase";
import NotFound from "./pages/NotFound";
import OrganizationList from "./pages/OrganizationList";
import Profile from "./pages/Profile";
import Setting from "./pages/Setting";
import Signup from "./pages/Signup";
import UserList from "./pages/UserList";
import CollectionAnalytics from "./pages/CollectionAnalytics";
import { getUserStorageType, getUserToken } from "./utills/global";
import { isJwtExpired } from "./utills/jwt";
import { setStorage } from "./utills/storage";
import useUserInformation from "./utills/useUserSession";

const auth = getUserToken();

function App() {
  const { userSession, verifyReduxToken, autoLogin } = useUserInformation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoggedIn, setIsLoggedIn] = useState(userSession as any);
  const queryParams = new URLSearchParams(window.location.search);
  const refreshToken = queryParams.get("rt");
  const redirectionUrl = window.location.href.split('/?rt=')?.[0];

  useEffect(() => {
    if (userSession) {
      if (isJwtExpired(userSession)) {
        verifyReduxToken();
      }
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userSession]);

  useEffect(() => {
    if (refreshToken && !getUserToken()) {
      const pathSplit = window.location.pathname.split('/');
      const routeBusinessId = pathSplit?.[2];
      const routeCollectionId = pathSplit?.[3];
      setStorage(`${getUserStorageType()}refreshToken`, refreshToken);
      autoLogin(routeBusinessId, routeCollectionId, redirectionUrl);
      setIsLoggedIn(true);
    }
  }, [refreshToken]);

  if (userSession && isJwtExpired(userSession)) {
    return null;
  }
  

  
  return (
    <Router>
      <Routes>
        <Route path={`/login`} element={<Signup />} />
        <Route path={`/embed/:collectionId`} element={<IframeChat />} />
        {auth && <Route path={`/`} element={<OrganizationList />} />}

        {!auth && <Route path={`*`} element={<Signup />} />}
        {auth && <Route path={`*`} element={<OrganizationList />} />}
        {auth && <Route path={`/profile`} element={<Profile />} />}
        {auth && <Route path={`/settings/:businessId`} element={<Setting />} />}
        {auth && <Route path={`/billing`} element={<Billing />} />}
        {auth && <Route path={`/collection`} element={<Collection />} />}
        {auth && <Route path={`/inviteusers/:businessId/:collectionId`} element={<InviteUsers />} />}
        {auth && <Route path={`/users/:businessId/:collectionId`} element={<UserList />} />}
        {auth && <Route path={`/adddocuments/:businessId/:collectionId`} element={<Documents />} />}
        {auth && <Route path={`/chat/:businessId/:collectionId`} element={<Chat />} />}
        {auth && <Route path={`/collections/:businessId`} element={<CollectionList />} />}
        {auth && <Route path={`/documents/:businessId/:collectionId`} element={<DocumentList />} />}
        {auth && <Route path={`/knowledgebase/:businessId/:collectionId`} element={<Knowledgebase />} />}
        {auth && <Route path={`/embediframe/:collectionId/:chatId`} element={<IframeChat />} />} 
        {auth && <Route path={`/analytics/:businessId/:collectionId`} element={<CollectionAnalytics />} />}      
        {auth && <Route path={`*`} element={<NotFound />} />}
        </Routes>
    </Router>
  )
}

export default App;
