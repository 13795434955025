/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popconfirm, Progress, Space, Table, message, Tooltip } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue } from 'antd/es/table/interface';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import React, { FC, useEffect, useState } from 'react';
import Docx from "../../images/Docx.svg";
import Doc from "../../images/doc.svg";
import PPT from "../../images/ppt.svg";
import TEXT from "../../images/text.svg";
import { headers } from '../../utills/axios';
import { VS_PORTAL_API_URL } from '../../utills/constants';
import { deleteImgPath, pdfImgPath ,urlCopyImgPath} from "../../utills/images";
import { VS_EMBED_URL } from "../../utills/constants";
import Rectangle272 from "../../images/Rectangle272.svg";
import { GlobalOutlined } from '@ant-design/icons';
 


interface DataType {
  name: string;
  type: string;
  createdAt: string;
  url: string;  
  id: number;
  Embed: string;
  isPublic : string

}

interface StatusDataType {
  progress: string;  
  id: number;
}

interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
  keyword: any;
}


interface Props {
    collectionId: any,
    searchString: any,
    isAdmin: any,
    handleEditQuestion: any
}



const DocumentsTable: FC<Props> = ({
    collectionId,
    searchString,
    isAdmin,
    handleEditQuestion
}) => {
  const [data, setData] = useState<DataType[]>();
  const [documentStatus, setDocumentStatus] = useState<StatusDataType[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    keyword: searchString
  });

  const getRandomuserParams = (params: TableParams) => ({
    limit: params.pagination?.pageSize,
    page: params.pagination?.current,
    keyword: searchString
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Document',
      dataIndex: 'name',
      width: '50%',
      className: 'mySection action-btn',
      render: (name: any, record: any) => {
        return <Space>
            <span>{name}</span>
            {isAdmin && (
              <a
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditQuestion(record)
                }}
                className="edit-imgHover"
                rel="noreferrer"
                key={`documents_table_row_url_${record.id}`}
              >
                <img src={Rectangle272} alt="" />
              </a>
            )}
        </Space>
     }

    },
    {
      title: 'Type',
      dataIndex: 'type',
      className:'table-heading',
      width: '10%',
      render: (name: any, record: any) => {
        const Image = ImageDisplay(record.type)
        let imageTags: any = '';
        if(record.type === 'url') {
          imageTags = <GlobalOutlined />
        } else {
          imageTags = <img className='document-image'  
          src={Image} 
          alt=""
           key={`documents_table_row_pdf_icon_${record.id}`}
           />
        }
        return <Space>
          {imageTags}            
            <span className='table-context-text text-uppercase text-cls'>{record.type || 'PDF'}</span>
        </Space>
      }
    },
    {
      title: 'Size',
      dataIndex: 'size',
      className:'table-heading',
      width: '10%',
      render: (name: any, record: any) => {
        const formattedSize = formatBytes(record.size);
        return <Space>
            <span className='table-context-text text-cls'>{formattedSize}</span>
        </Space>
      }
    },
    {
      title: "Embed?",
      dataIndex: "isPublic",
      width: "10%",
      className: "table-heading",
      render: (isEmbed, record: any) => {
        if (isEmbed) {
          const linkToCopy = `<iframe style="width: 100% !important" src="${VS_EMBED_URL}${collectionId}?documentId=${record.id}" width="100%" height="800" scrolling="no" allow="microphone *"></iframe>`;
          return (
            <><span className="text-cls">Embed</span><Space onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(linkToCopy).then(() => {
              }, () => {
              });
            } }>
              <Tooltip title="Click to copy link">
                <img src={urlCopyImgPath}></img>
              </Tooltip>
            </Space></>
          );
        }
        return null;
      },
    },

    {
      title: 'Date Uploaded',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend'],
      dataIndex: 'createdAt',
      width: '20%',
      className: 'file-size dates table-heading action-btn',
      render: (name: any, record: any) => {
        return <Space>
            <p className='text-cls'>{moment(record.createdAt).format('MMM DD, YYYY')}</p>
                              <Popconfirm
                                placement="right"
                                title={'Are you sure to delete this document?'}
                                description={''}
                                onConfirm={(event: any) => {event.stopPropagation(),deleteDocument(record.id)}}
                                okText="Yes"
                                cancelText="No"
                              >
                                <a href="#." className="ms-4" key={`document_delete_${record.id}`}  onClick={(event) => event.stopPropagation()}>
                                  <img src={deleteImgPath} alt="" />
                                </a>
            </Popconfirm>
        </Space>

    },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className:'table-heading',
      width: '20%',
      render: (name: any, record: any) => {
        if (record.status < 100) {
          const percentage = Number(documentStatus?.filter((statusItem: StatusDataType) => statusItem.id === record.id)[0]?.progress);
          return <Space>
              <div style={{ width: 170 }}>
                <Progress percent={percentage} size="small" />
              </div>
          </Space>
        } else {
          return <Space>
              <div style={{ width: 170 }}>
                <Progress percent={100} size="small" />
              </div>
          </Space>
        } 
      }
    }
  ];
  

  const deleteDocument = (documentId: any) => {
    axios.delete(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents/${documentId}`, { headers: headers() })
      .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
        if (response.data.success) {
          message.success('Document Deleted Succcessfully');
          fetchData();
        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          setLoading(false)
        }
      });
  }

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents?${qs.stringify(getRandomuserParams(tableParams))}`, { headers: headers() })
      .then((response: {
        data: {
          objects: {
            documents: any[];
            total: number;
            isPublic: boolean;
          };
          success: boolean;
          message: string;
        };
      }) => {
        if (response.data.success) {
          const objects = response.data.objects;
  
          if (objects && objects.documents) {
            // Add the isPublic field to each document based on the common isPublic flag
            const documentsWithPublicInfo = objects.documents.map((doc: any) => ({
              ...doc,
              isPublic: objects.isPublic, // assuming `isPublic` is a common property
            }));
  
            setData(documentsWithPublicInfo);
            setTableParams({
              ...tableParams,
              pagination: {
                ...tableParams.pagination,
                total: objects.total || 0,
              },
            });
          }
  
          setLoading(false);
        } else {
          message.error(response.data.message);
          setLoading(false);
        }
      })
      .catch((err: { response: { data: { success: boolean; message: string } } }) => {
        if (!err?.response?.data?.success) {
          setLoading(false);
        }
      });
  };
  

  const fetchDocumentsStatus = (pendingDocumentList: any) => {
      axios
      .post(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents/status`, {
        ids: pendingDocumentList.map((documentItem : any) => documentItem.id)
      }, { headers: headers() })
      .then((response: {
        data: {
          objects: any; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          if (response.data.objects) {
            setDocumentStatus(response.data.objects)
          }
          
        } else {
          message.error(response?.data.message)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          //message.error(err?.response?.data.message)
        }
      });
  };

  const ImageDisplay = (fileType : any ) => {
    if (fileType === 'docx') {
      return Docx
    } else if (fileType === 'ppt') {
      return PPT
    }else if (fileType === 'text') {
      return TEXT
    } else if (fileType === 'doc') {
      return Doc
    } else {
       return pdfImgPath
    }
    }

const formatBytes = (bytes: any) => {
    if (bytes < 1024) {
      return bytes + ' B';
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else {
      return (bytes / 1073741824).toFixed(2) + ' GB';
    }
  }

  const openLink = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };

  useEffect(() => {
    fetchData();    
  }, [JSON.stringify(tableParams), searchString, collectionId]);

  useEffect(() => {
    setInterval(() => {
      const pendingDocumentList = data?.filter((item: any) => item.status < 100);
      if (pendingDocumentList?.length && window.location.href.indexOf('/documents/') !== -1) {
        fetchDocumentsStatus(pendingDocumentList)
      }
    }, 5000);
  }, [data]);
   
  const handleTableChange = (
    pagination: TablePaginationConfig,
    keyword: any
  ): any => {
    setTableParams({
      pagination,
      keyword
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <Table
      columns={columns}
      rowKey={(record: any) => record.id}
      size="small"
      style={{marginTop:'1rem'}}
      dataSource={data}
      pagination={tableParams.pagination}
      loading={loading}
      onChange={handleTableChange}
      rowClassName={'orgRight-row'}
      onRow={(record: any) => {
        return { onClick: () => openLink(record?.url)};
      }}
    />
  );
};

export default DocumentsTable;