/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import SubHeader from "../components/header/SubHeader";
import { headers } from "../utills/axios";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { getStorage } from "../utills/storage";


// eslint-disable-next-line no-empty-pattern
const CollectionAnalytics = ({

}) => {

  const routeParams = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false as boolean);

  const collectionId = routeParams.collectionId || getStorage('collectionId');

  const [analyticsURL, setAnalyticsURL] = useState('');

  const getAnalyticsCollectionDetails = () => {
    setLoading(true)
    setAnalyticsURL('')
    axios
      .get(`${VS_PORTAL_API_URL}/collections/${collectionId}/analytics`, { headers: headers() })
      .then((response: {
        data: {
          objects: any; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          if (response.data.objects) {
            setAnalyticsURL(response.data.objects.embedUrl)
          }
          setLoading(false)

        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }


  useEffect(() => {
    getAnalyticsCollectionDetails()
  }, [collectionId]);

  return (
    <div style={{
      width: '100%',
      backgroundColor: '#F3F5F7'
    }} key={'document-parent-container'}>
      <Header></Header>
      <Spin tip="Loading" size="large" spinning={loading}>
        <SubHeader showAnalytics={true}></SubHeader>
        <section className="collectionForm modal-dialog-scrollable">
          <div className="col-lg-12 col-md-6 col-sm-6 col-12" style={{ padding: 10 }}>
            <iframe
              src={analyticsURL}
              style={{
                height: "100vh",
                width: "100%",
              }}
            ></iframe>
          </div>
        </section>
      </Spin>
      <Footer></Footer>
    </div>
  );
};

export default CollectionAnalytics;
