import React, { useEffect } from "react";
import { VS_PRICING_TABLEID, VS_PRICING_PUBLISH_KEY } from "../utills/constants";
import { getStorage } from "../utills/storage";

const Billing = () => {
    const userId = getStorage("userId");
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return React.createElement("stripe-pricing-table", {
        "pricing-table-id": `${VS_PRICING_TABLEID}`,
        "publishable-key": `${VS_PRICING_PUBLISH_KEY}`,
        "client-reference-id": `${userId}`
    });
};

export default Billing;