/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Input, Space, Spin, message } from "antd";
import axios from "axios";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import SubHeader from "../components/header/SubHeader";
import BackIcon from "../images/Back.svg";
import { headers } from "../utills/axios";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { isAdmin, isEditor } from "../utills/global";
import DocumentsTable from "./Table/DocumentsTable";
import { getStorage } from "../utills/storage";
import Close from '../images/Close.png';

interface Props {
  isFromModal?: boolean
}
const DocumentList: FC<Props> = ({
  isFromModal
}) => {
  
  const suggestionQuestionListRef = useRef(null) as any
  const navigate = useNavigate();
  const routeParams = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false as boolean);
  const [searchString, setSearchString] = useState('' as string);
  const bottomRef = useRef(null) as any
  const [isModalOpen, setisModalOpen] = useState(false as boolean)
  const [documentId, setDocumentId] = useState('' as string)

  const [formData, setFormData] = useState({
    suggestedQuestions: '' as string
} as any)

  const collectionId = routeParams.collectionId || getStorage('collectionId');
  const businessId = routeParams.businessId || getStorage('businessId');

  const [documentsList, setDocumentsList] = useState([] as any);
  const getDocumentsList = () => {
    setLoading(true)
    setDocumentsList([])
    axios
      .get(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents`, { headers: headers() })
      .then((response: {
        data: {
          objects: any; success: any; message: React.SetStateAction<string>;
        };
      }) => {
        if (response.data.success) {
          if (response.data.objects) {
            setDocumentsList(response.data.objects.documents)
          }
          setLoading(false)

        } else {
          message.error(response?.data.message)
          setLoading(false)
        }
      }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
        if (!err?.response?.data?.success) {
          if (err?.response?.data.message) {
            message.error(err?.response?.data.message)
          }
          setLoading(false)
        }
      });
  }

  const navigateToAddDocument = () => {
    navigate(`/adddocuments/${businessId}/${collectionId}`);
  }

  const onChange = (field: string, value: any) => {
    formData[field] = value;
    setFormData({ ...formData })
}

  useEffect(() => {
    getDocumentsList()
  }, [collectionId]);


  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [documentsList]);

  const handleGoBack = () => {
    navigate(`/collections/${businessId}`);
  }

  const updateSuggestionQuestionList = () => {   
    setLoading(true);
    axios
        .put(
            `${VS_PORTAL_API_URL}/collections/${collectionId}/documents/${documentId}`,
            {
                suggestedQuestions: formData.suggestedQuestions.split('\n')
            },
            { headers: headers() }
        )
        .then((response: any) => {
            if (response.data.success) {
                setLoading(false);
                message.success('Documents Details Updated Successfully');
                setisModalOpen(false);
                getDocumentsList();
            } else {
                message.error(response?.data.message);
                setLoading(false);
            }
        })
        .catch((err: any) => {
            if (!err?.response?.data?.success) {
                message.error(err?.response?.data.message);
                setLoading(false);
            }
        });
};

  const formValidation = () => {
    return true;
  }

  const onUpdate = (event: any) => {
    event.preventDefault();
    if (formValidation()) {
        formData.error = '';
        setFormData(formData);
        updateSuggestionQuestionList(); // Call without parameters
    }
    return false;
  };

  const handleEditQuestion = (item: any) => {
    setisModalOpen(true)
    setTimeout(() => suggestionQuestionListRef.current.focus(), 300);
    setFormData({ suggestedQuestions: item?.suggestedQuestions.join('\n') });
    setDocumentId(item?.id)
    
  }

  const showModal = () => {
    return (
        <div className="modal mt-20 fade show" id="add-collection-popup" data-bs-backdrop="false" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" style={{ display: 'block', paddingTop: '5rem', paddingRight: '16px' }} aria-modal="true" role="dialog">
            <div className="modal-content add-doc-modal-width">
                <div className="modal-header add-doc-pop-title">
                    <h5 className="modal-title" id="staticBackdropLabel">{'Edit Document'}</h5>
                    <a onClick={() => setisModalOpen(false)} data-bs-dismiss="modal" aria-label="Close" className="add-doc-closed-pop">
                        <img src={Close} />
                    </a>
                </div>

                <div className="modal-body">
                    <form onSubmit={(event) => { onUpdate(event) }}>
                        <div className="mb-3">
                            <label htmlFor="question" className="form-label ">Suggestion Questions</label>                            
                            <Input.TextArea
    ref={suggestionQuestionListRef}
    rows={4}
    cols={50}
    value={formData.suggestedQuestions} // Bind the value to formData
    style={{
        background: '#f5f5f5',
        resize: 'none',
        marginBottom: 8
    }}
    onChange={(event) => onChange('suggestedQuestions', event.target.value)}
/>

                        </div>

                        {!!formData.error &&
                            <><Space>
                                <Alert message={formData.error} type="error" showIcon />
                            </Space><br></br></>
                        }

                        <button type="submit" className="btn btn-voice-submit mb-3" disabled={formData.suggestedQuestions == ''}>{'Save'}</button>
                    </form>
                </div>
            </div><div className="modal-dialog modal-dialog-centered">
            </div>
        </div>
    )
}

  return (
    <div style={{
      width: '100%',
      backgroundColor: '#F3F5F7'
    }} key={'document-parent-container'}>
      {!isFromModal && <Header></Header>}
      <Spin tip="Loading" size="large" spinning={false}>
        {!isFromModal && <SubHeader showAnalytics={true}></SubHeader>}
        <section id="addDocumentSection">
          <div className="container mt-4 mb-4">
            <div className="row">
              {isFromModal &&
                <><div className={isAdmin(businessId) ? 'col-lg-6 col-md-3 col-12' : 'col-lg-8 col-md-3 col-12'}></div>
                  <div className={isAdmin(businessId) ? 'col-lg-6 col-md-3 col-12 justify-content-end mt-4' : 'col-lg-4 col-md-3 col-12 justify-content-end mt-4'}>
                    <Space>
                      <input
                        className="form-control ads-search textSearch"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        onChange={(e) => {
                          setSearchString(e.target.value);
                        }} />                      
                      {isAdmin(businessId) && (
                        <a href="#." onClick={() => navigateToAddDocument()} className="add-doc-top-btn ps-butn ps-btn-left" key={'add-document'}  style={{
                          width: 180
                        }}>
                          Add Documents
                        </a>
                      )}
                    </Space>
                  </div></>
              }
              {!isFromModal &&
                <>
                <div className="col-lg-4 col-md-3 col-12 ads-title heading text-start">
                  <Space direction="horizontal">
                    <a onClick={() => handleGoBack()}> <img src={BackIcon} /></a>
                    <h1 style={{ fontSize: '24px' }} className="mt-2">
                      Documents <span>({documentsList.length || 0} {documentsList.length <= 1 ? 'file' : 'files'})</span>
                    </h1>
                  </Space>
                </div>
                <div className="col-lg-8 col-md-6 col-12 d-flex flex-wrap justify-content-end align-items-center anchor-margin">
                    <Space>
                      <input
                        className="form-control ads-search textSearch"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        onChange={(e) => {
                          setSearchString(e.target.value);
                        }} />
                      {(isAdmin(businessId) || isEditor(businessId)) && (
                        <a href="#." onClick={() => navigateToAddDocument()} className="add-doc-top-btn ps-butn ps-btn-left" key={'add-document'} style={{
                          width: 180,
                          marginRight: 8
                        }}>
                          Add Documents
                        </a>
                      )}
                    </Space>
                  </div></>
              }
            </div>
          </div>
        </section>
        <section className="collectionForm modal-dialog-scrollable">
        <div className="container-fluid table-list">
              <div className="container-fluid content-container">
                <div className="container">
                  <DocumentsTable collectionId={collectionId} searchString={searchString} isAdmin={isAdmin(businessId)} handleEditQuestion={(item: any) => handleEditQuestion(item)}></DocumentsTable>
                </div>
              </div>
          </div>
        </section>
      </Spin>
      {isModalOpen && showModal()}
      {!isFromModal && <Footer />}
    </div>
  );
};

export default DocumentList;
