/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { setUserSession } from '../utills/storageManager';
import { getUserRefreshToken, isJwtExpired } from "../utills/global";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { clearStorage } from "../utills/storage";

let refreshTokenPromise: any = null;

const refreshToken = (isAutoLogin?: boolean) => {
    return new Promise(async (resolve, reject) => {
        const refreshToken: any = getUserRefreshToken();
        // Logout function
        const logout = (error: any) => {
            clearStorage();
            window.location.replace("/login");
            reject(error)
        }

        // Verify an expired refreshToken to push the user to the onboarding
        if (isJwtExpired(refreshToken) && !isAutoLogin) {
            const error = new Error('User session expired. Expired refresh t****')
            logout(error)
            return
        }

        if (refreshToken) {
            await axios.post(`${VS_PORTAL_API_URL}/users/refresh_token`, {
                token: refreshToken
            }).then(({ data }) => {
                if (data?.success) {
                    setUserSession(data.objects)
                    return resolve(data)
                } else {
                    logout(new Error('User session expired. Cannot refresh t****'))
                }
            }).catch(error => {
                // When no internet connection is present it returns
                if (error.code !== "ERR_NETWORK") {
                    logout(error)
                }
                // Don't logout if the network API fails
                reject(error)
            })
        } else {
            const error = new Error('Missing refreshT****')
            logout(error)
        }
    })
}

export const refreshTokenPerform = async (isAutoLogin?: boolean) => {
    // Avoid concurrent refresh token API calls
    let webToken, webRefreshToken, loggedUserId;
    if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken(isAutoLogin).then((data: any) => {
            webToken = data?.objects?.token
            webRefreshToken = data?.objects?.refresh_token
            loggedUserId = data?.objects?.user_id;
            // Once we get the token refreshed we clear the promise
            // so any API call can continue
            refreshTokenPromise = null
        }).catch(() => {
            // Ignore the error so far
            // Refresh token didn't work
            // We just clear the refresh promise
            refreshTokenPromise = null
        })
    }

    // Here any API call should be waiting to "refresh token" to finish
    await refreshTokenPromise
    if (webToken) return { webToken, webRefreshToken, loggedUserId }
}