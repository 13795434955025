/* eslint-disable @typescript-eslint/no-explicit-any */
import { BarChartOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Popover, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CollapseIcon from "../../images/Collapse.svg";
import CollapseAddIcon from "../../images/CollapseAdd.svg";
import { ReactComponent as DocumentIcon } from '../../images/Documents1.svg';
import { ReactComponent as UserIcon } from '../../images/TabUsers.svg';
import DocumentList from "../../pages/DocumentList";
import { setCurrentUserData } from "../../utills/global";
//import { searchImgPath } from "../../utills/images";
import { getStorage, setStorage } from "../../utills/storage";


interface Props {
  hideUser?: boolean
  showAnalytics?: boolean
}

const SubHeader = ({ hideUser, showAnalytics }: Props) => {
  const navigate = useNavigate();
  const routeParams = useParams();
  const collectionId = routeParams.collectionId;
  const businessId = routeParams.businessId;
  
  const [organizationList, setOrganizationList] = useState([] as any);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false as boolean);
  const [collectionContainerVisible, setCollectionContainerVisible] = useState(false as boolean);
  const [searchText, setSearchText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isChatPage: boolean = window.location.href.indexOf('chat') !== -1;
  const [activeTab, setActiveTab] = useState('1') as any;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigateToChat = (selectedBusinessId: any, selectedCollectionId: any, selectedCollectionName: any, selectedBusinessName: any, selectedBusinessImage: any) => {
    setStorage('businessName', selectedBusinessName);
    setStorage('businessId', selectedBusinessId);
    setStorage('businessImageUrl', selectedBusinessImage);
    setStorage('collectionName', selectedCollectionName);
    setStorage('collectionId', selectedCollectionId);
    setStorage('newChatInitiated', true);
    navigate(`/chat/${selectedBusinessId}/${selectedCollectionId}`, { replace: true });
    setCollectionContainerVisible(false)
  }

  const navigateToUsers = () => {    
    localStorage.setItem('activeTab', '3')
    navigate(`/users/${businessId}/${collectionId}`);
  }

  const navigateToCollectionAnalytics = () => {
    localStorage.setItem('activeTab', '2')
    navigate(`/analytics/${businessId}/${collectionId}`);
  }

  const navigateToDocument = () => {
    localStorage.setItem('activeTab', '1')
    setCollectionContainerVisible(false)
    if (hideUser) {
      setIsModalOpen(true)
    } else {
      navigate(`/documents/${businessId}/${collectionId}`);
    }
  }

  interface DataType {
    location?: string;
    imageUrl?: string;
    key: React.Key;
    name: string;
    age: number;
    address: string;
    description: string;
  }

  const getDisplayCollectionName = () => {    
      const imageStyle = {
        width: '32px',
        height: '32px',
      };
      const nameStyle = {
        marginLeft: '16px',
        marginTop: '8px',
      };
      const collectionName = getStorage('collectionName') || '';
      const businessName = getStorage('businessName') || '';
      const businessImageUrl = getStorage('businessImageUrl') || '';
      const altText = businessImageUrl ? "" : businessName.charAt(0).toUpperCase();
      const orgImage = businessImageUrl ? (
        <img
          src={businessImageUrl}
          className="rounded-circle"
          alt={altText}
          title="Organization"
          style={imageStyle}
        />
      ) : (
        <div
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={imageStyle}
        >
          <span className="first-letter">{businessName.charAt(0).toUpperCase()}</span>
        </div>
      );
      return <div
        className="d-flex align-items-center col-lg-4 col-md-4 col-sm-4 col-12 orgRow-left"
      >
        {orgImage}
        <div style={{ ...nameStyle, whiteSpace: 'nowrap' }}>
          <div className="col-lg-8 col-8">
            <h1>{businessName}</h1>
            <p>{collectionName}</p>
          </div>
        </div>
      </div>
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'id',
      className: "tbl-header",
      render: (orgname, row) => {
        const imageStyle = {
          width: '32px',
          height: '32px',
        };
        const nameStyle = {
          marginLeft: '16px',
          marginTop: '8px',
        };
        const altText = row.imageUrl ? "" : row.name.charAt(0).toUpperCase();
        const orgImage = row.imageUrl ? (
          <img
            src={row.imageUrl}
            className="rounded-circle"
            alt={altText}
            title="Organization"
            style={imageStyle}
          />
        ) : (
          <div
            className="rounded-circle d-flex align-items-center justify-content-center"            
          >
            <span className="circlesmall">{row.name.charAt(0).toUpperCase()}</span>
          </div>
        );
        return <div
          className="d-flex align-items-center col-lg-4 col-md-4 col-sm-4 col-12 orgRow-left"
        >
          {orgImage}
          <div style={{ ...nameStyle, whiteSpace: 'nowrap' }}>
            <div className="col-lg-8 col-8">
              <h1 className="mb-3">{row.name}</h1>
              <p>{row.location}</p>
            </div>
          </div>
        </div>
      }
    },
    Table.EXPAND_COLUMN
  ];

  const searchByText = (searchTextEvent: any) => {
    setSearchText(searchTextEvent.target.value);
  }

  const getOrganizationList = () => {
    setOrganizationList([]);
    const userBusinessList: any = getStorage('businesses') != undefined ? JSON.parse(getStorage('businesses') as any) : [];
    const userCollectionList: any = getStorage('collections') != undefined ? JSON.parse(getStorage('collections') as any) : [];

    const containerCollectionList = userBusinessList?.map((businessItem: any) => {
      businessItem.collections = userCollectionList?.filter((collectionItem: any) => businessItem.id == collectionItem.businessId)
      return businessItem
    })
    setOrganizationList(containerCollectionList)

  };

  useEffect(() => {
    if (getStorage('businesses') == undefined || getStorage('collections') == undefined || getStorage('businessId') == undefined || getStorage('collectionId') == undefined) {
      setCurrentUserData()
    } else {
      getOrganizationList()
    }
  }, [getStorage('businesses'), getStorage('collections'), getStorage('businessId'), getStorage('collectionId')]);

  useEffect(() => {
    getOrganizationList()
  }, []);

  useEffect(() => {
    setActiveTab(localStorage.getItem('activeTab') as any)
  }, [localStorage.getItem('activeTab')]);

  return (
    <section id="collectName1" className="bg-white" style={{
      height: isChatPage ? 60 : undefined
    }}>
      <div className="container sub-header" key={'document-container'}>
        <div className="row" style={{
          marginTop: '4px',
         
        }}>
          <div className="col-lg-6 col-12 col-md-2 col-sm-2 d-flex align-items-center collection-container">
            {!hideUser &&
              <a href="#." className="d-flex align-items-center cn-title">
                <p className="px-2">{getStorage('collectionName')}</p>
              </a>
            }
            {hideUser &&
              <Popover placement="bottomLeft" destroyTooltipOnHide={true} content={
                <>
                  <div className="search-container-icon user-search search-txt">
                    {/*<a href="#.">
                      <img className="user-sbar-i" src={searchImgPath} title="search" alt="search" />
                    </a>*/}
                    <input className="form-control ads-search user-sbar-ip search-txt" type="search" placeholder="Search" aria-label="Search" onChange={(e) => searchByText(e)} />
                  </div>
                  <div className="ant-popover-inner-div">
                    <Space direction="vertical" style={{
                      width: '100%',
                    }}>
                      <Row style={{
                        width: '480px',
                        height: '50px',
                        padding: '16px 0px 8px 0px',
                        borderBottom: '1px solid #f0f0f0'
                      }}>
                        <Col className="tbl-header" span={12}>Collection</Col>
                        <Col className="tbl-header" span={12} style={{
                          textAlign: 'right'
                        }}>Date Uploaded</Col>
                      </Row>
                      <Table
                        loading={loading}
                        columns={columns}
                        key={'collection_table'}
                        scroll={{ y: 280 }}
                        rowKey={'id'}
                        expandable={{
                          expandedRowRender: (record: any) => <div style={{ margin: 0, background: 'white' }}>
                            {record.collections.filter((collectionItem: any) => collectionItem.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).map((cItem: any, index: any) => {
                              return <Row key={'cRow_' + index} className="child-row" onClick={() => navigateToChat(record.id, cItem.id, cItem.name, record.name, record.imageUrl)}>
                                <Col span={12}><span key={index} style={{ color: '#758AF0' }}>{cItem.name}</span></Col>
                                <Col span={12} style={{
                                  textAlign: 'end'
                                }}>{cItem.lastUploadedOn ? moment(cItem.lastUploadedOn).format('MMM DD, YYYY') : ''}</Col>
                              </Row>
                            })}
                          </div>,
                          defaultExpandAllRows: true,
                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <img src={CollapseIcon} className="expand-icon"
                                onClick={e => onExpand(record, e)}></img>
                            ) : (
                              <img src={CollapseAddIcon} className="expand-icon"
                                onClick={e => onExpand(record, e)}></img>
                            )
                        }}
                        dataSource={organizationList}
                        pagination={false}
                      />
                    </Space>
                  </div>
                </>
              } trigger="click" open={collectionContainerVisible}>
                <a href="#." className="d-flex align-items-center cn-title" onClick={() => {
                  setCollectionContainerVisible(!collectionContainerVisible)
                }}>
                  <p className="px-2">{getDisplayCollectionName()}</p>
                  <CaretDownOutlined style={{
                    marginBottom: 16
                  }} />
                </a>
              </Popover>
            }
          </div>
          <div className="col-lg-6 col-12 col-md-10 col-sm-10 d-flex justify-content-end cDU">
            <ul className="nav">
              <li className={activeTab == '1' ?  'nav-item navActive': 'nav-item'}  key={'chat-link'} onClick={() => navigateToDocument()} style={{
                cursor: 'pointer'
              }}>
                <a                 
                  className={'nav-link d-flex cn-content'}
                  aria-current="page"
                >
                  <DocumentIcon />
                  <p className={'px-2'}>Documents</p>
                </a>
              </li>
              {showAnalytics &&
                <li className={activeTab == '2' ?  'nav-item navActive': 'nav-item'} style={{
                  display: 'block',
                  cursor: 'pointer'
                }} key={'analytics-link'} onClick={() => navigateToCollectionAnalytics()}>
                  <a
                    className={'nav-link d-flex cn-content'}
                    aria-current="page"                    
                  >
                    <BarChartOutlined style={{ fontSize: '22px' }} />
                    <p className={'ps-2'}>Analytics</p>
                  </a>
                </li>
              }
              {!hideUser &&
                <li className={activeTab == '3' ?  'nav-item navActive': 'nav-item'} style={{
                  display: 'block',
                  cursor: 'pointer'
                }} key={'users-link'} onClick={() => navigateToUsers()}>
                  <a
                    className={'nav-link d-flex cn-content'}
                    aria-current="page"                    
                  >
                    <UserIcon />
                    <p className={'ps-2'}>Users</p>
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
      <Modal title="Documents" centered width={1250} open={isModalOpen}
        onCancel={handleCancel}
        cancelText="Close"
        footer={[
          <Button key='close-document-modal' type="primary" onClick={handleCancel}>Close</Button>
        ]}>
        <DocumentList isFromModal={true}></DocumentList>
      </Modal>
    </section>

  );
};

export default SubHeader;
