/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/header/Header";
import { headers } from "../utills/axios";
import { VS_PORTAL_API_URL } from "../utills/constants";
import { getStorage, setStorage } from "../utills/storage";
import { deleteImgPath, userImgPath } from "../utills/images";
import { ReactComponent as ChatIcon } from '../images/Chat.svg';

const InviteUsers = () => {

    const navigate = useNavigate();

    const routeParams = useParams();

    const [loading, setLoading] = useState(false as boolean);

    const collectionId = routeParams.collectionId || getStorage('collectionId');

    const businessId = routeParams.businessId || getStorage('businessId');

    const navigateToChat = () => {
        setLoading(false)
        setStorage('newChatInitiated', true);
        navigate(`/chat/${businessId}/${collectionId}`, { replace: true });
    }

    const formData = {
        email: '' as string,
        roleId: 3,
        collectionId: collectionId as any
    }

    const [formList, setFormList] = useState([{
        email: '' as string,
        roleId: 3,
        collectionId: collectionId as any,
    }] as any)

    const onChange = (field: string, index: any , value: any) => {
        formList[index][field] = value;
        console.log('formList', formList);
        setFormList([...formList])
    }

    const sendUsersInvitation = () => {
        setLoading(true)
        axios
            .post(`${VS_PORTAL_API_URL}/businesses/${businessId}/invite`, formList, { headers: headers() })
            .then((response: { data: { success: any; message: React.SetStateAction<string>; }; }) => {
                if (response.data.success) {
                    navigateToUsers()
                } else {
                    message.error(response?.data.message)
                    setLoading(false)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                    setLoading(false)
                }
            });
    }

    const addRow = () => {
        formList.push(formData)
        setFormList([...formList])
    }

    const deleteRow = (index: any) => {
        formList.splice(index, 1);
        setFormList([...formList])
    }

    const navigateToUsers = () => {
        navigate(`/users/${businessId}/${collectionId}`, { replace: true });
    }

    return (
        <>
            <Header />
            <Spin tip="Loading" size="large" spinning={loading}>
            <section id="collectName1" className="bg-white">
          <div className="container" key={'document-container'}>
            <div className="row">
              <div className="col-lg-6 col-12 col-md-2 col-sm-2 d-flex align-items-center">
                <a href="#." className="d-flex align-items-center cn-title">
                  <p className="px-2">{getStorage('collectionName')}</p>
                </a>
              </div>
              <div className="col-lg-6 col-12 col-md-10 col-sm-10 d-flex justify-content-end cDU">
                <ul className="nav">
                  <li className="nav-item" key={'chat-link'}>
                    <a
                      className="nav-link active d-flex cn-content"
                      aria-current="page"
                      onClick={() => navigateToChat()}
                    >
                      <ChatIcon className="active" stroke="#C1CCD6" />
                      <p className="px-2">Chat</p>
                    </a>
                  </li>
                  <li className="nav-item" style={{
                    display: 'block'
                  }} key={'users-link'}>
                    <a
                      className="nav-link active d-flex cn-content"
                      aria-current="page"
                      href="#"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 18C3 15.7909 4.79086 14 7 14L11 14C13.2091 14 15 15.7909 15 18"
                          stroke="#C1CCD6"
                          style={{
                            strokeWidth: 2,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                          }}
                        ></path>
                        <path
                          d="M17 15L19 15C20.1046 15 21 15.8954 21 17L21 18"
                          stroke="#C1CCD6"
                          style={{
                            strokeWidth: 2,
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                          }}
                        ></path>
                        <ellipse
                          cx="9"
                          cy="8"
                          rx="4"
                          ry="4"
                          transform="rotate(-90 9 8)"
                          stroke="#C1CCD6"
                          style={{
                            strokeWidth: 2
                          }}
                        ></ellipse>
                        <circle
                          cx="17.5"
                          cy="10.5"
                          r="2.5"
                          transform="rotate(-90 17.5 10.5)"
                          stroke="#C1CCD6"
                          style={{
                            strokeWidth: 2
                          }}
                        ></circle>
                      </svg>
                      <p className="ps-2">Users</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
            <section className="collectioName">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-8">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-2 user-icon">
                                    <img src={userImgPath} alt="user icon" title="Invite Users to CollectionName" />
                                </div>
                                <div className="col-lg-7 user-content">
                                    <h1 style={{
                                        width: 'max-content'
                                    }}>Invite Users to CollectionName</h1>
                                    <p>An editor can upload, edit and delete documents. A viewer can only view documents</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="collectionForm">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <form action="" method="post">                                
                                {
                                    formList.map((formItem: any, index: any) => {
                                        return (
                                        <div className="formcollection-inner mb-2" key={'row_' + index}>
                                        <div className="email">
                                            <input type="email" name={'email_' + index} value={formItem.name} onChange={(event) => onChange('email', index, event.target.value)}/>
                                        </div>
                                        <div className="collect d-flex me-auto">
                                            <div className="collect-field">
                                                <input type="radio" name={'roleId_' + index} value="2" checked={formItem.roleId == 2} onChange={(event) => onChange('roleId', index, event.target.value)}/>
                                                <label>Editor</label>
                                            </div>
                                            <div className="collect-field">
                                                <input type="radio" name={'roleId_' + index} value="3" checked={formItem.roleId == 3} onChange={(event) => onChange('roleId', index, event.target.value)}/>
                                                <label>Viewer</label>
                                            </div>
                                        </div>
                                        { index !== 0 &&
                                        <div className="delete">
                                            <a href="#.">
                                                <img src={deleteImgPath} title="delete" alt="delete" onClick={() => deleteRow(index)}/>
                                            </a>
                                        </div>
                                        }
                                    </div>)
                                    })
                                }
                                                              
                            </form>
                        </div>
                    </div>
                    <a href="#." className="addemail" onClick={() => addRow()}>Add email</a>
                </div>
            </section>
            <section className="userLater">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6 anytime">
                            <p>You can add users later anytime</p>
                        </div>
                        <div className="col-6 btn-invite text-end d-flex">
                            <a href="#." onClick={()=> navigateToUsers()}>Skip</a>
                            <a href="#." onClick={() => sendUsersInvitation()} className={formList.filter((formItem: any) => !!formItem.email).length !== formList.length ? 'linkDisabled' : ''}>Invite Users</a>
                        </div>
                    </div>
                </div>
            </section>
            </Spin>
        </>
    );
};

export default InviteUsers;