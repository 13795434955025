/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint: Missing "key" prop for element in iterator  react/jsx-key */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from '../components/header/Header';
import SubHeader from "../components/header/SubHeader";
import { getStorage, removeStorage, setStorage } from "../utills/storage";
import IframeChat from "./IframeChat";

const Chat = () => {

    const routeParams = useParams();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false as boolean);

    const [chatId, setChatId] = useState('' as any);

    const [chatInitiated, setChatInitiated] = useState(false as boolean);

    const collectionId = routeParams.collectionId || getStorage('collectionId');

    
    

    useEffect(() => {
        setStorage('isFromRouter', true);
    }, [collectionId, chatId]);



    useEffect(() => {
        if (collectionId) {
            setChatId('');
            //getChatList();
        }
    }, [collectionId]);


    useEffect(() => {
        if (getStorage('newChatInitiated')) {
            setChatInitiated(!chatInitiated)
            removeStorage('newChatInitiated');
        }
    }, [getStorage('newChatInitiated')]);
   


    return (
        <><div className="w-100 overflow-hidden" style={{ maxHeight: '210vh' }}>
            <Header></Header>
            <SubHeader hideUser={true}></SubHeader>
            <section className="message-area">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-12">
                    <IframeChat></IframeChat>
                    </div>
                        {/*<div className="col-12">
                            <div className="chat-area">                                
                                <div className="chatbox">
                                <IframeChat></IframeChat>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </section>
            {/*<Footer />*/}
        </div>
        </>
    );
};

export default Chat;