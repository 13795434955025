/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { message, Progress } from 'antd';

import DocumentsHeader from "../components/header/DocumentsHeader";
import InviteSentIcon from "../images/invite-sent.svg";
import AiIcon from "../images/AI.svg";
import { setStorage } from '../utills/storage';
import { VS_PORTAL_API_URL } from '../utills/constants';
import { headers } from '../utills/axios';

// Type definitions
type ApiResponseDocument = {
  id: string;
  name: string;
  progress: number;
};

type DocumentProgressType = {
  id: string;
  name: string;
  progress: number;
  isCompleted: boolean;
};

const Knowledgebase = () => {
  const routeParams = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [isDocumentProgress, setIsDocumentProgress] = useState(false);
  const [documentProgress, setDocumentProgress] = useState<DocumentProgressType[]>([]);

  const collectionId = routeParams.collectionId;
  const businessId = routeParams.businessId;

  // Create a map for efficient data lookup
  const dataMap = useMemo(() => {
    const map = new Map();
    data.forEach(doc => map.set(doc.id, doc.name));
    return map;
  }, [data]);

  const navigateToChat = () => {
    setStorage('newChatInitiated', true);
    navigate(`/chat/${businessId}/${collectionId}`, { replace: true });
  };

  const fetchData = () => {
    setLoading(true);
    axios.get(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents`, { headers: headers() })
      .then(response => {
        if (response.data.success) {
          setData(response.data.objects?.documents || []);
        } else {
          message.error(response?.data.message);
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        message.error('Error fetching documents');
      });
  };

  const fetchDocumentsStatus = () => {
    axios
      .post(`${VS_PORTAL_API_URL}/collections/${collectionId}/documents/status`, {
        ids: data.map((documentItem) => documentItem.id)
      }, { headers: headers() })
      .then(response => {
        if (response.data.success && response.data.objects) {
          const updatedProgress = response.data.objects.map((doc: ApiResponseDocument) => {
            return {
              id: doc.id,
              name: dataMap.get(doc.id) || 'Unknown Document',
              progress: doc.progress,
              isCompleted: doc.progress === 100
            };
          });
          setDocumentProgress(updatedProgress);
  
          if (updatedProgress.every((doc: DocumentProgressType) => doc.isCompleted)) {
            setIsDocumentProgress(true);
          }
        } else {
          message.error(response?.data.message);
        }
      }).catch(() => {
        message.error('Error fetching document status');
      });
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (data.length && !isDocumentProgress) {
        fetchDocumentsStatus();
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, [data, isDocumentProgress, dataMap])

  return (
    <>
      <DocumentsHeader />
      <section className="sent-invitation">
        <div className="container">
          {isDocumentProgress ? (
            <div className="row">
              <div className="col-lg-12 text-center pt-3 pb-3 pt-sm-6 pb-sm-6">
                <img src={AiIcon} alt="" />
              </div>
              <div className="col-lg-12 text-center mb-5">
                <img src={InviteSentIcon} alt="" className="d-inline-block me-2" />
                <h1 className="d-inline-block knb-built">Knowledgebase built</h1>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-9 m-auto">
                <a href="#." onClick={() => navigateToChat()} className="btn-voice-submit text-center">Chat with your documents</a>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="inner-sent-invite knowledgebase pt-6 pb-20">
                <div className="col-lg-12 text-center">
                  <span>
                    <img src={AiIcon} alt="" />
                    <h1 className="d-inline-block knb-built">Building Knowledge Base</h1>
                  </span>
                </div>
                {documentProgress.filter(doc => !doc.isCompleted).map((doc: DocumentProgressType, index: number) => (
                  <div key={index} className="col-lg-4 single-progress">
                    <div className="document-name">{doc.name}</div>
                    <Progress strokeColor="#758AF0" percent={doc.progress} status="active" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Knowledgebase;
