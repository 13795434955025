/* eslint-disable @typescript-eslint/no-explicit-any */
import { Space, Spin, message } from "antd";
import axios from "axios";
import React, { FC, useState } from "react";
import Close from "../../images/Close.png";
import { headers } from "../../utills/axios";
import { VS_PORTAL_API_URL } from "../../utills/constants";
import { deleteImgPath } from "../../utills/images";
import { getStorage } from "../../utills/storage";
import { useNavigate } from "react-router-dom";

interface Props {
  modalClose: any;
  collectionId: any;
  businessId: any;
}

const WebPageUrl: FC<Props> = ({ modalClose, collectionId, businessId }) => {

  const [loading, setLoading] = useState(false as boolean);
  const [formList, setFormList] = useState([
    {
      url: '' as string,
      title: '' as string
    },
  ] as any);
  const navigate = useNavigate();

    const decode = (val: any) => {
        if (typeof val === 'string') {
            return JSON.parse(val);
        }
    }

  const onChange = (field: string, index: any, value: any) => {
    formList[index][field] = value;
    setFormList([...formList]);
  };

  const formData = {
    url: '' as string
  };

  const deleteRow = (index: any) => {
    formList.splice(index, 1);
    setFormList([...formList]);
  };

  const addRow = () => {
    formList.push(formData);
    setFormList([...formList]);
  };

  const navigateToDocumentsList = () => {
    navigate(`/documents/${businessId}/${collectionId}`, { replace: true });
}

const navigateToKnowledgebase = () => {
    navigate(`/knowledgebase/${businessId}/${collectionId}`, { replace: true });
}

  const saveWebpageUrls = () => {
    setLoading(true);
    axios
      .post(`${VS_PORTAL_API_URL}/collections/${collectionId}/urls`, formList, {
        headers: headers(),
      })
      .then(
        (response: {
          data: { success: any; message: React.SetStateAction<string> };
        }) => {
          if (response.data.success) {
            modalClose();
            setLoading(false);
            message.success(`Webpage URL details added successfully.`);
            const validFormat = decode(getStorage(`signIn`))
                    if (validFormat) {
                        navigateToDocumentsList();
                    } else {
                        navigateToKnowledgebase();
                    }
          } else {
            message.error(response?.data.message);
            setLoading(false);
          }
        }
      )
      .catch(
        (err: {
          response: {
            data: { success: any; message: React.SetStateAction<string> };
          };
        }) => {
          if (!err?.response?.data?.success) {
            message.error(err?.response?.data.message);
            setLoading(false);
          }
        }
      );
  };

  return (
    <div
      className="modal mt-20 fade show"
      id="add-webpage-url-popup"
      data-bs-backdrop="false"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      style={{
        display: "block",
        paddingTop: "5rem",
        paddingRight: "16px",
        marginTop: "0.5rem",
      }}
      aria-modal="true"
      role="dialog"
    >
      <div
        className="modal-content-invite add-doc-modal-width"
        style={{ maxWidth: "45em" }}
      >
        <div className="modal-header add-doc-pop-title">
          <h5 className="modal-title" id="staticBackdropLabel">
           Add Documents URL
          </h5>
          <a
            onClick={modalClose}
            data-bs-dismiss="modal"
            aria-label="Close"
            className="add-doc-closed-pop"
          >
            <img src={Close} />
          </a>
        </div>

        <div
          className="modal-body"
          style={{
            backgroundColor: "#F3F5F7",
            gap: "20px",
            borderBottomRightRadius: "1.5rem",
            borderBottomLeftRadius: "1.5rem",
          }}
        >
          <Spin tip="Loading" size="large" spinning={loading}>
            <section className="collectionForm">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <form action="" method="post">
                      {formList.map((formItem: any, index: any) => {
                        return (
                          <div
                            className="formcollection-inner mb-2"
                            key={"row_" + index}
                          >
                            <Space>
                                <input
                                  type="text"
                                  name={"url_" + index}
                                  value={formItem.url}
                                  onChange={(event) =>
                                    onChange("url", index, event.target.value)
                                  }
                                  placeholder="https://"
                                />
                            </Space>
                            {index !== 0 && (
                              <div className="delete">
                                <a href="#.">
                                  <img
                                    src={deleteImgPath}
                                    title="delete"
                                    alt="delete"
                                    onClick={() => deleteRow(index)}
                                  />
                                </a>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </form>
                  </div>
                </div>
                <a
                  href="#."
                  className="addemail"
                  style={{ marginTop: "1rem" }}
                  onClick={() => addRow()}
                >
                  Add more
                </a>
              </div>
            </section>

            <div className="d-flex flex-row-reverse">
              <div className="invite-btn text-end ">
                <a
                  href="#."
                  onClick={() => saveWebpageUrls()}
                  className="text-white"
                >
                  Add Documents
                </a>
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default WebPageUrl;
