/* eslint-disable @typescript-eslint/no-explicit-any */
import { googleLogout } from "@react-oauth/google";

import type { MenuProps } from 'antd';
import { Dropdown, Space, message } from 'antd';
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../images/Back.svg";
import { headers } from "../../utills/axios";
import { VS_CONTACT_URL, VS_DEFAULT_ORG_IMAGE, VS_HELP_URL, VS_PORTAL_API_URL } from "../../utills/constants";
import { headerChatIcon, logoImgPath, manageIcon } from "../../utills/images";
import { clearStorage, getStorage, setStorage } from "../../utills/storage";

interface Props {
    showGoBack?: boolean
    handleGoBackFunc?: any
    imageUpload?: boolean
}


const Header: FC<Props> = ({
    showGoBack,
    handleGoBackFunc,
    imageUpload
}) => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const userId = getStorage('userId');
    const collectionId = getStorage('collectionId') || routeParams.collectionId;
    const businessId = getStorage('businessId') || routeParams.businessId;

    const imageUrls: any = getStorage('imageUrls') != undefined ? JSON.parse(getStorage('imageUrls') as any) : [];
    const defaultImage = imageUrls?.length ? imageUrls?.[0] : VS_DEFAULT_ORG_IMAGE;
    const [profileImage, setProfileImage] = useState(defaultImage)
    const navigateToBusiness = () => {
        navigate(`/organizations`, { replace: true });
    }

    const logOut = () => {
        googleLogout();
        axios
            .post(`${VS_PORTAL_API_URL}/users/${userId}/logout`, {}, { headers: headers() })
            .then((response: {
                data: {
                    [x: string]: any; success: any; message: React.SetStateAction<string>
                }
            }) => {
                if (response.data.success) {
                    clearStorage();                    
                    message.success('Logout Successfully');
                    navigate(`/login`, { replace: true, state: { Signin: "true" } });                  

                } else {
                    message.error(response?.data.message)
                }
            }).catch((err: { response: { data: { success: any; message: React.SetStateAction<string>; }; }; }) => {
                if (!err?.response?.data?.success) {
                    message.error(err?.response?.data.message)
                }
            });
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a href=" " onClick={() => navigate(`/settings/${businessId}`)}>
                    Settings
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a href={VS_HELP_URL}>
                    Help
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a href={VS_CONTACT_URL}>
                    Contact Us
                </a>
            ),
        },
        {
            key: '4',
            label: (
                <a href="#." onClick={logOut}>
                    Sign Out
                </a>
            ),
        }
    ];

    const goBack = () => {
        handleGoBackFunc()
    }

    const getProfileImageUrls = () => {
        return getStorage('imageUrls')
    }

    const navigateToChat = () => {
        setStorage('newChatInitiated', true);
        navigate(`/chat/${businessId}/${collectionId}`, { replace: true });
    }

    useEffect(() => {
        const imageUrls: any = JSON.parse(getProfileImageUrls() as any);
        if (imageUrls?.length) {
            setProfileImage(imageUrls[0])
        }

    }, [imageUpload]);


    return (
        <>
            <header className="headerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-center main-column">
                            <div className="logo">
                                <Space direction="horizontal">
                                    {showGoBack && <a onClick={() => goBack()}> <img src={BackIcon} alt="back-arrow" title="back arrow" /></a>}
                                    <a href="/" style={{
                                        marginLeft: showGoBack ? 24 : 0
                                    }}><img src={logoImgPath} alt="logo" title="VoiceSphere" /></a>
                                </Space>
                            </div>

                            <div className="top-menu">
                                <ul style={{ marginBottom: '0.2rem', }} className="top-org d-flex align-items-center flex-wrap ">
                                    <li className=" d-flex align-items-center" style={{
                                        cursor: 'pointer'
                                    }}>
                                        <a href="#." onClick={() => navigateToChat()}><img src={headerChatIcon} alt="icon" title="avatar icon" className="mt-1 rounded-circle" style={{
                                            width: 32,
                                            height: 32
                                        }} /></a>
                                        <a href="#." onClick={() => navigateToChat()}>Chat</a>
                                   </li>
                                    <li className=" d-flex align-items-center" style={{
                                        cursor: 'pointer'
                                    }}>
                                        <a href="#." onClick={() => navigateToBusiness()}><img src={manageIcon} alt="icon" title="avatar icon" className="mt-1 rounded-circle" style={{
                                            width: 32,
                                            height: 32
                                        }} /></a>
                                        <a href="#." onClick={() => navigateToBusiness()}>Manage</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-1 user-profile-pic flex-items-right">
                                <Dropdown menu={{ items }}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <img src={profileImage} alt="profile photo" title="user photo" />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
        </>
    )

}

export default Header;